import { Image } from "antd-mobile";
import React, { memo } from "react";
import { CardTypeData } from "@/pages/Chat/feature/ShareCards";

type ShareFollowedProps = {
    data: CardTypeData;
}
const avatarList = Array(12).fill(0).map((item, index) => {
    return require(`@/assets/avatar/image ${ 692 + index }.webp`);
});


const AvatarGroup = (props: ShareFollowedProps) => {
    const { data } = props;
    const avatarIndex = (data.openTime || Date.now()) % 9;
    const list = avatarList.slice(avatarIndex, avatarIndex + 3);
    return <div className="my-avatar-group">
        <div className="avatar-group-user">
            {
                list.map((item, index) => {
                    return <Image src={ item } key={ index } className="avatar-image"></Image>;
                })
            }
        </div>
    </div>;
};

export default memo(AvatarGroup);
