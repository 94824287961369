import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import { addConversationList, fetchConversationList, setTotalUnread, updateConversationList } from "@/store/slice/home";
import type { ConversationListener } from "@chat/zim-sdk";
import { addConversationListener, removeConversationListener } from "@chat/zim-sdk";
// import { setConversationDetail } from "@/store/slice/chat";
import { getVisibleUnReadCount, sendUnreadChange } from "@/utils";
import { useLatest } from "ahooks";

function useHomeInit() {
    const dispatch = useAppDispatch();
    const { list } = useAppSelector(state => state.home);
    const { logged } = useAppSelector(state => state.user);
    const latestList = useLatest(list);
    useEffect(() => {
        dispatch(fetchConversationList());
        if (logged) {
            const onConversationChanged: ConversationListener["onConversationChanged"] = (infoList) => {
                infoList.forEach(item => {
                    const { conversation } = item;
                    const exist = latestList.current.find(item => item.conversationID === conversation.conversationID);
                    if (exist) {
                        dispatch(updateConversationList(conversation));
                    } else {
                        dispatch(addConversationList(conversation));
                    }
                });
                const unreadCount = getVisibleUnReadCount();
                sendUnreadChange(unreadCount);
            };
            const onTotalUnreadMessageCountChanged: ConversationListener["onTotalUnreadMessageCountChanged"] = (totalUnreadMessageCount: number) => {
                dispatch(setTotalUnread(totalUnreadMessageCount));
            };
            addConversationListener({
                onConversationChanged,
                onTotalUnreadMessageCountChanged
            });
            return () => {
                removeConversationListener();
            };
        }
    }, [logged]);
}

export default useHomeInit;
