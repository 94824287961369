import React from "react";
import type { ZIMMessage } from "@chat/zim-sdk";
import {
    MessageStatus,
    MessageReceiptStatus,
    ConversationType
} from "@chat/zim-sdk";
import classnames from "classnames";
import {
    ReadStatus,
    // ReadStatus,
    Sending
} from "@/components/themeSvg";
import { getMmaTime } from "@chat/shared";
import { useParams } from "react-router-dom";

type MessageStateProps = {
    data: ZIMMessage;
    style?: React.CSSProperties;
    className?: string
}

function MessageState(props: MessageStateProps) {
    const { data, className, style } = props;
    const { conversationType = -1 } = useParams();
    return <div className={ classnames("message-state", className) } style={ style }>
        <span>{ getMmaTime(data.timestamp) }</span>
        {
            data.sentStatus === MessageStatus.Sending && <Sending className="message-icon-sending"/>
        }
        {
            data.sentStatus === MessageStatus.Success && +conversationType === ConversationType.Peer &&
            <ReadStatus read={ data.receiptStatus === MessageReceiptStatus.Done } className="message-icon-read"/> }
    </div>;
}

export default MessageState;
