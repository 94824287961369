import { createSlice } from "@reduxjs/toolkit";
import { ZIMRoomMemberInfo } from "@chat/zim-sdk";
import { RoomItem } from "@/apis/room";
import _ from "lodash";

const initialState = {
    groupDetail: {} as RoomItem | null,
    memberList: [] as ZIMRoomMemberInfo[],
    totalCount: 0,
    scroll: {
        top: 0,
        left: 0
    },
    adminList: [] as string[]
};
export const groupSetting = createSlice({
    name: "groupSetting",
    initialState,
    reducers: {
        updateGroupDetail: (state, action) => {
            state.groupDetail = {
                ...state.groupDetail,
                ...action.payload
            };
        },
        updateGroupMember: (state, action) => {
            state.memberList.push(...action.payload);
        },
        updateScroll: (state, action) => {
            state.scroll = action.payload;
        },
        setTotalCount: (state, action) => {
            state.totalCount = action.payload;
        },
        setAdminList: (state, action) => {
            state.adminList = action.payload;
        },
        resetState: (state) => {
            return {
                ..._.omit(initialState, ['adminList']),
                adminList: state.adminList
            };

        }
    }
});
export const {
    updateGroupDetail,
    updateGroupMember,
    updateScroll,
    resetState,
    setTotalCount,
    setAdminList
} = groupSetting.actions;

export default groupSetting.reducer;
