import { Image, Space } from "antd-mobile";
import { toast } from "@/utils";
import { copyText } from "@chat/shared";
import { useAppDispatch, useAppSelector } from "@/store";
import { setUserLike } from "@/apis/room";
import {
    getUserByGroupId,
    setBigVisible,
} from "@/store/slice/personal";
import ToggleHeart from "@/pages/Chat/feature/Modal/PersonalModal/feature/ToggleHeart";
import store2 from "store2";


export default function User() {
    const {
        userID,
        userName,
        likeCount,
        myLike,
        userAvatarUrl,
        extendedData
    } = useAppSelector(state => state.personal.user);
    const dispatch = useAppDispatch();
    const toggleLike = () => {
        setUserLike({
            targetUserId: userID,
            imUserId: store2.get("userID") || "",
            state: myLike
        }).then((res: any) => {
            if (res.status === 500) {
                toast(res.message, "error");
                return;
            }
            dispatch(getUserByGroupId());
            // dispatch(setUserLikeCount(res.data.likeCount));
            // dispatch(setUserMyLike(res.data.myLike));
        });
    };
    const copy = () => {
        copyText(userID, () => toast("Copied successfully"));
    };
    return <div className="user">
        <Space align="center" style={ { "--gap-horizontal": "16px" } }>
            <div className="user-avatar" onClick={ () => dispatch(setBigVisible(true)) }>
                <Image src={ userAvatarUrl } className="avatar-image" fit="cover"></Image>
                <Image src={ require(`@/assets/VIP/v${ extendedData?.level || 0 }.webp`) }
                       className="vip-image"></Image>
            </div>
            <div className="user-message">
                <div className="name">{ userName }</div>
                <div className="userId" onClick={ copy }>User ID:{ userID }</div>
            </div>
        </Space>
        <Space align="center" style={ { "--gap-horizontal": "8px", marginLeft: "auto" } }>
            <div className="user-heart">
                <div className="heart-image">
                    <ToggleHeart state={ myLike } onClick={ toggleLike }/>
                </div>
                <div className="num">{ likeCount }</div>
            </div>
        </Space>


    </div>;
}
