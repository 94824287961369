import { isNumber, getVmSize } from "@chat/shared";
import React from "react";

const colorList = [
    "#ffda00",
    "#38a8e0",
    "#393c3c",
    "#ef7d00",
    "#00d58d",
    "#4b58c9",
    "#6b6a69",
    "#ff306e",
    "#e30613",
    "#24cd1a"
];
type ReflexCarProps = {
    type: string
}
const colorObj: Record<string, string> = {
    "B": "#38a8e0",
    "S": "#ef7d00",
    "O": "#24cd1a",
    "E": "#e30613"
};

function ReflexQuickRace(props: ReflexCarProps) {
    const { type } = props;
    return <div className="reflex-container">
        <div className="reflex">
            <div className="reflex-item" style={ {
                background: isNumber(type) ? colorList[parseInt(type)] : colorObj[type],
                color: "#fff",
                fontSize: getVmSize(14),
                borderRadius: getVmSize(4)
            } }>
                {
                    type
                }
            </div>
        </div>
    </div>;
}

export default ReflexQuickRace;
