import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor();

    return <span role="img" { ...props }>
<svg viewBox="0 0 3 3" fill="none" color={ color }>
    <circle cx="1.50098" cy="1.69531" r="1" fill="currentColor"/>
</svg>
    </span>;
}

export default memo(ThemeIcon);
