import React, { memo } from "react";
import { useAppSelector } from "@/store";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const { value } = useAppSelector(state => state.common.theme);

    return <span role="img" { ...props }>

        <svg viewBox="0 0 22 22"
             color={ value["--whats-color-t3"] }>
  <path
      d="M2.14258 10.9976V1.48987C2.14258 0.720065 2.97591 0.23894 3.64258 0.623842L11.8765 5.37773L20.1105 10.1316C20.7771 10.5165 20.7771 11.4788 20.1105 11.8637L11.8765 16.6176L3.64258 21.3715C2.97591 21.7564 2.14258 21.2752 2.14258 20.5054V10.9976Z"
      fill="currentColor"/>
</svg>
    </span>;
}

export default memo(ThemeIcon);
