import mitt from 'mitt';

type Events = {
    CHAT_LIST_SCROLL_TO_BOTTOM: {behavior?: "smooth" | "auto", index?: number};
    CHAT_LIST_SCROLL_BY_SHOW_BTN: void;
    INPUT_FOCUS: void;
    VOICE_END: {blob?: Blob, duration: number, file?: File}
};

const emitter = mitt<Events>();


export default emitter;
