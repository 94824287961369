import { createImageMessage, createVideoMessage, ZIMMessage } from "@chat/zim-sdk";

export interface FileWithPath extends File {
    path?: string;
}

export default function useFileMessage() {
    const createImageOrVideoMessage = async (
        file: FileWithPath,
    ): Promise<ZIMMessage> => {
        try {
            const isImage = file.type.includes("image");
            if (isImage) {
                return createImageMessage(file);
            }
            return createVideoMessage(file)
        } catch (e) {
            return Promise.reject(e);
        }

    };
    return {
        createImageOrVideoMessage,
    };
}

