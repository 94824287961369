import React, { memo } from "react";
import { useAppSelector } from "@/store";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const { value } = useAppSelector(state => state.common.theme);

    return <span role="img" { ...props }>
<svg viewBox="0 0 22 22" color={ value["--whats-color-t3"] }>
  <rect x="2.45514" y="1" width="6.34432" height="20" rx="1" fill="currentColor"/>
  <rect x="13.2012" y="1" width="6.34432" height="20" rx="1" fill="currentColor"/>
</svg>
    </span>;
}

export default memo(ThemeIcon);
