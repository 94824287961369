import React from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import { setLoginModalVisible } from "@/store/slice/common";
import { sendLoginMessage } from "@/utils";
import SettingModal from "@/components/SettingModal";

function LoginModal() {
    const visible = useAppSelector(state => state.common.loginModalVisible);
    const dispatch = useAppDispatch();
    const cancel = () => {
        dispatch(setLoginModalVisible(false));
    };
    return <SettingModal
        visible={ visible }
        title="Friendly reminder"
        cancel={ cancel }
        confirm={ sendLoginMessage }
        confirmText="Register"
    >
        Please log in first before proceeding !
    </SettingModal>;
}

export default LoginModal;
