import React from "react";
import "./twiceModal.scss";
import { useAppDispatch, useAppSelector } from "@/store";
import { setTwiceVisible } from "@/store/slice/common";
import { setNoticeBarVisible } from "@/store/slice/chat";
import SettingModal from "@/components/SettingModal";


function TwiceModal() {
    const visible = useAppSelector(state => state.common.twiceVisible);
    const dispatch = useAppDispatch();
    const cancel = () => {
        dispatch(setTwiceVisible(false));
    };
    const confirm = () => {
        dispatch(setNoticeBarVisible(false));
        dispatch(setTwiceVisible(false));
    };
    return <SettingModal
        visible={ visible }
        confirm={ confirm }
        cancel={ cancel }
        title="Warm Reminder"
    >
        Are you sure you want to turn off the group announcement? Once turned off, this
        notice will no longer be displayed.
    </SettingModal>;
}

export default TwiceModal;
