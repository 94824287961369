import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor("--whats-color-t3");

    return <span role="img" { ...props }>
        <svg viewBox="0 0 24 24" fill="none" color={ color }>
  <g clipPath="url(#clip0_8037_6713)">
    <path
        d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM15.5 11C16.33 11 17 10.33 17 9.5C17 8.67 16.33 8 15.5 8C14.67 8 14 8.67 14 9.5C14 10.33 14.67 11 15.5 11ZM8.5 11C9.33 11 10 10.33 10 9.5C10 8.67 9.33 8 8.5 8C7.67 8 7 8.67 7 9.5C7 10.33 7.67 11 8.5 11ZM12 17.5C14.33 17.5 16.31 16.04 17.11 14H6.89C7.69 16.04 9.67 17.5 12 17.5Z"
        fill="currentColor"/>
  </g>
  <defs>
    <clipPath id="clip0_8037_6713">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>
    </span>;
}

export default memo(ThemeIcon);
