import "./chatGroupSetting.scss";
import { useParams } from "react-router-dom";
import {
    ConversationType,
    getRoomMemberList as getSDKRoomMemberList,
    getRoomMemberCount as getSDKRoomMemberCount,
} from "@chat/zim-sdk";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import { resetState, setTotalCount, updateGroupMember } from "@/store/slice/groupSetting";
import Header from "@/pages/Chat/feature/Modal/SettingModal/Header";
import Card from "@/pages/Chat/feature/Modal/SettingModal/Card";
import Notification from "@/pages/Chat/feature/Modal/SettingModal/Notification";
import Member from "@/pages/Chat/feature/Modal/SettingModal/Member";

function ChatGroupSetting() {
    const visible = useAppSelector(state => state.chat.settingVisible);
    const { conversationID = "", conversationType = ConversationType.Unknown } = useParams();
    const dispatch = useAppDispatch();
    // 获取房间成员
    const getRoomMemberList = async (conversationID: string, nextFlag = "") => {
        try {
            const res = await getSDKRoomMemberList(conversationID, { count: 100, nextFlag });
            dispatch(updateGroupMember(res.memberList));
            if (res.nextFlag) {
                getRoomMemberList(conversationID, res.nextFlag);
            }
        } catch (err) {
            console.error(err);
        }
    };
    // 获取房间的在线人员总数
    const getRoomMemberCount = async (conversationID: string) => {
        try {
            const res = await getSDKRoomMemberCount(conversationID);
            dispatch(setTotalCount(res.count));
        } catch (err) {
            console.error(err);
        }
    };
    const init = async () => {
        if (+conversationType === ConversationType.Room) {
            await Promise.all([getRoomMemberCount(conversationID), getRoomMemberList(conversationID)]);
        }
    };
    useEffect(() => {
        if (visible) {
            init();
        } else {
            dispatch(resetState());
        }
    }, [visible, conversationID]);
    return <div className="chatGroupSetting">
        <Header/>
        <Card/>
        <Notification/>
        <Member/>
    </div>;
}

export default ChatGroupSetting;
