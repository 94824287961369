import { useUserInfo } from "@/hooks/useUserInfo";
import { ZIMMessage } from "@chat/zim-sdk";
import photoIcon from "@/assets/svg/photo.svg";
import cls from "./lasetMessageStyle.module.scss"

type PicProps = {
    message: ZIMMessage
};

export default function Pic(props: PicProps) {
    const { senderUserID } = props.message;
    const { userInfo } = useUserInfo(senderUserID);
    return <div className={cls.container}>
        <span>{ userInfo?.userName }:</span>
        <img src={ photoIcon } className="text-inner-image" alt=""/>
        Photo
    </div>;
}
