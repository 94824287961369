import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor();

    return <span role="img" { ...props }>
<svg viewBox="0 0 25 24" fill="none" color={ color }>
  <path
      d="M10.5684 4.01123C10.5684 3.12132 11.2898 2.3999 12.1797 2.3999C13.0696 2.3999 13.791 3.12132 13.791 4.01123V4.99365H10.5684V4.01123Z"
      fill="currentColor"/>
  <path
      d="M12.1797 21.626C13.219 21.626 14.0615 20.7834 14.0615 19.7441H10.2979C10.2979 20.7834 11.1404 21.626 12.1797 21.626Z"
      fill="currentColor"/>
  <path d="M5.60498 17.8242H16.5581" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
        strokeLinejoin="round"/>
  <path d="M3.64923 4.71289L19.4956 20.5593" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
        strokeLinejoin="round"/>
  <path
      d="M16.4258 17.6469H7.4502V10.0774C7.4502 9.83901 7.47015 9.60531 7.50848 9.37784M10.14 6.17123C10.6027 5.99466 11.1049 5.89795 11.6296 5.89795H12.8704C15.1786 5.89795 17.0498 7.76914 17.0498 10.0774V12.9695"
      stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    </span>;
}

export default memo(ThemeIcon);
