import MessageItem from "@/pages/Home/features/MessageItem";
import { useAppSelector } from "@/store";
import SelectSetting from "@/pages/Home/features/SelectSetting";

function MessageList() {
    const homeStore = useAppSelector(state => state.home);
    const { list } = homeStore;
    return <>
        <SelectSetting/>
        <div className="message">
            {
                list.map((item) => {
                    return <MessageItem key={ item.conversationID } data={ item }></MessageItem>;
                })
            }
        </div>
    </>;

}

export default MessageList;
