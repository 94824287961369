import { useAppSelector } from "@/store";

function useBawThemeColor() {
    const { value, name } = useAppSelector(state => state.common.theme);
    switch (name) {
        case "dark":
            const darkColor = value["--whats-button-icon"];
            return [darkColor];
        case "light":
        default:
            return ["white"];
    }
}

export default useBawThemeColor;
