import { useMemo } from "react";
import SettingModal from "@/components/SettingModal";
import { useAppDispatch, useAppSelector } from "@/store";
import { getUserByGroupId, setCancelMutedVisible } from "@/store/slice/personal";
import { toast } from "@/utils";
import { useCountDown } from "@chat/shared";
import { useCustomMessage } from "@/hooks/useCustomMessage";
import { useParams } from "react-router-dom";

function CancelMuteModal() {
    const { cancelMutedVisible, user, userId } = useAppSelector(state => state.personal);
    const { sendMuteFlag } = useCustomMessage();
    const dispatch = useAppDispatch();
    const { conversationID } = useParams();
    const {
        days,
        seconds,
        hours,
        minutes
    } = useCountDown(+user.muteExpiredTime, () => {
        dispatch(setCancelMutedVisible(false));
    });
    const cancel = () => {
        reset();
    };
    const onConfirm = () => {
        sendMuteFlag(conversationID!, userId, 0).then(res => {
            dispatch(getUserByGroupId());
            toast("Lift the silence for this user!");
            reset();
        });
    };
    const reset = () => {
        dispatch(setCancelMutedVisible(false));
    };
    const title = useMemo(() => {
        return `Unmute ${ user.userName }?`;
    }, [user]);
    const remindTime = useMemo(() => {
        if (+days <= 0) {
            return `${ hours }:${ minutes }:${ seconds }`;
        }
        return `${ days } days ${ hours }:${ minutes }:${ seconds }`;
    }, [days, seconds, hours, seconds]);
    return <SettingModal
        title={ title }
        visible={ cancelMutedVisible }
        cancel={ cancel }
        confirm={ onConfirm }
    >
        <div>
            Remaining: <span
            style={ { color: "#F15802" } }>{ remindTime }</span>
        </div>
    </SettingModal>;
}

export default CancelMuteModal;
