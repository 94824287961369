import React from "react";
import "./EditorModal.scss";
import { useAppDispatch, useAppSelector } from "@/store";
import { setEditorModalVisible } from "@/store/slice/common";
import { sendUserEditorMessage } from "@/utils";
import SettingModal from "@/components/SettingModal";

function EditorModal() {
    const visible = useAppSelector(state => state.common.editorModalVisible);
    const dispatch = useAppDispatch();
    const cancel = () => {
        dispatch(setEditorModalVisible(false));
    };
    return <SettingModal
        title="Update Profile"
        visible={ visible }
        cancel={ cancel }
        cancelText="Cancel"
        confirm={ sendUserEditorMessage }
        confirmText="To Reset"
    >
        <div>To enter the group chat, please update your profile information.</div>
    </SettingModal>;
}

export default EditorModal;
