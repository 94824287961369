import { recharge } from "@/apis/app";
import { setRechargeVisible } from "@/store/slice/chat";
import { toast } from "@/utils";
import { useAppDispatch, useAppSelector } from "@/store";
import useSendMessage from "@/pages/Chat/feature/hooks/useSendMessage";
import { createTextMessage } from "@chat/zim-sdk";

export default function useRecharge() {
    const { rechargeBalanceList, rechargeChannel, conversationDetail } = useAppSelector(state => state.chat);
    const dispatch = useAppDispatch();
    const { sendMessage } = useSendMessage();
    const toRecharge = async (rechargeValue: string) => {
        try {
            const balance = rechargeBalanceList.find(item => item.balance === +rechargeValue);
            let id: number | string = "";
            if (balance) {
                id = balance.id;
            }
            const res = await recharge({
                payTag: rechargeChannel.payTag,
                balanceId: id || 0,
                payTypeId: rechargeChannel.id,
                rechargeBalance: id ? 0 : rechargeValue
            });
            if (res.code === 0) {
                const message = createTextMessage("I want to recharge " + rechargeValue)
                await sendMessage({message, conversationID: conversationDetail.conversationID});
                // if (/^I want to recharge/.test(chatMessage)) {
                //     dispatch(setChatMessage(""));
                // }
                dispatch(setRechargeVisible(false));
            } else {
                toast(res.msg, "error");
            }
        } catch (err: any) {
            toast(err.msg, "error");
        }
    };
    return {
        toRecharge
    };
}
