import { Popup } from "antd-mobile";
import { useAppDispatch, useAppSelector } from "@/store";
import { Back } from "@/components/themeSvg";
import { hiddenVideo } from "@/store/slice/chat";
import cls from "./video-modal.module.scss";

function VideoModal() {
    const data = useAppSelector(state => state.chat.videoData);
    const dispatch = useAppDispatch();
    const snapshotWidth = data.videoFirstFrameWidth;
    const snapshotHeight = data.videoFirstFrameHeight;
    const width = window.innerWidth;
    const height = width * (snapshotHeight / snapshotWidth);
    return <Popup bodyStyle={ { width: "100vw", height: "100vh" } } visible={ data.visible }
                  destroyOnClose>
        <div className={ cls.modal }>
            <div className={ cls.header }>
                <div className={ cls.headerLeft }>
                    <Back theme="--whats-white" className={ cls.headerBack }
                          onClick={ () => dispatch(hiddenVideo()) }></Back>
                </div>
            </div>
            <div className={cls.video__content}>
                <video style={ { width, height } } poster={ data.videoFirstFrameDownloadUrl } src={ data.fileDownloadUrl }
                       className={ cls.video } muted autoPlay controls/>
            </div>

        </div>
    </Popup>;
}

export default VideoModal;
