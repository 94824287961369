import { createSlice } from "@reduxjs/toolkit";
import { IUserInfo } from "@chat/zim-sdk";
const initialState = {
    self: {} as IUserInfo,
    logged: false
};
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        updateSelf(state, action) {
            state.self = {
                ...state.self,
                ...action.payload,
            };
        },
        setLogged(state, action) {
            state.logged = action.payload;
        }
    }
});
export const {
    updateSelf,
    setLogged
} = userSlice.actions;


export default userSlice.reducer;
