import cls from "./foot.module.scss";
import { setLoginModalVisible } from "@/store/slice/common";
import { useAppDispatch } from "@/store";

function RegisterBottom() {
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(setLoginModalVisible(true));
    }
    return <div className={ cls.register } onClick={handleClick}>Register first, then message freely</div>;
}

export default RegisterBottom;
