import MessageState from "@/pages/Chat/feature/MessageItem/MessageState";
import { Image } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { toast } from "@/utils";
import {parseStr} from "@chat/shared"
import { ConversationType, ServiceCardItem, ZIMCustomMessage } from "@chat/zim-sdk";
// import { setConversationDetail } from "@/store/slice/chat";
// import { useAppDispatch } from "@/store";

type CardMessageProps = {
    data: ZIMCustomMessage
}
// 客服卡片
function CardMessage(props: CardMessageProps) {
    const { data } = props;
    const navigate = useNavigate();
    const cardElem: ServiceCardItem = parseStr(data.message);
    // const dispatch = useAppDispatch();
    const toChat = async (chatter: ServiceCardItem) => {
        try {
            navigate(`/chat/${ chatter.userID }/${ConversationType.Peer}`, {replace: true});
        } catch (err: any) {
            toast(err.errMsg || "Failed to open service chat", "error");
        }
    };
    return <div className="customCard">
        <div className="customCard-info">
            <Image src={ cardElem.faceURL } className="customCard-avatar"/>
            <div className="customCard-nickname">
                { cardElem.nickname }
                <MessageState data={ props.data } style={ { padding: 0 } }
                              className="message-state__bottom"></MessageState>
            </div>
        </div>
        <div className="customCard-chat" onClick={ () => toChat(cardElem) }>Send Message</div>
    </div>;
}

export default CardMessage;
