import { NoticeBar } from "antd-mobile";
import React from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import { setNoticeBarModalVisible } from "@/store/slice/chat";
import { Diturbing } from "@/components/themeSvg";

function NoticeBarCom() {
    const groupInfo = useAppSelector(state => state.chat.groupInfo);
    const dispatch = useAppDispatch();
    const noticeBarVisible = useAppSelector(state => state.chat.noticeBarVisible);
    if (!groupInfo || !noticeBarVisible) {
        return null;
    }
    const handleShowModal = () => {
        dispatch(setNoticeBarModalVisible(true));
    };
    return <div className="noticeBar-conatiner" onClick={ handleShowModal }>
        <NoticeBar
            className="noticeBar"
            icon={
                <div className="noticeBar-left">
                    <Diturbing className="icon_notice"></Diturbing>
                </div>
            }
            // extra={<Close className="noticeBar-right" />}
            content={ <div className="noticeBar-content">{ groupInfo.groupNotice }</div> }
        ></NoticeBar>
    </div>;
}

export default NoticeBarCom;
