import { useAppDispatch, useAppSelector } from "@/store";
import { deleteMessageModalHidden, deleteMessageModalShow, setQuoteMessage, setSelectState } from "@/store/slice/chat";
import { toast } from "@/utils";
import DeleteMessageModal from "@/pages/Chat/feature/Modal/DeleteMessageModal";
import { Back, Copy, Quote, Rubbish } from "@/components/themeSvg";
import { copyText } from "@chat/shared";
import { ConversationType, GroupMemberRole, MessageStatus, MessageType } from "@chat/zim-sdk";
import { useParams } from "react-router-dom";

function SelectNavbar() {
    const dispatch = useAppDispatch();
    const { isSelect, selectList, selfInfo } = useAppSelector(state => state.chat);
    const { conversationType = -1 } = useParams();
    const num = selectList.length;
    const closebar = () => {
        dispatch(setSelectState(false));
    };
    const chooseQuoteMessage = () => {
        const current = selectList[0];
        if (current.sentStatus !== MessageStatus.Success) {
            toast("Unable to reference unsent messages", 'error');
            return;
        }
        dispatch(setQuoteMessage(current));
        resetState();
    };
    const handlerCopy = () => {
        const current = selectList[0];
        if (current.type !== MessageType.Text as any) {
            toast("Only supports text copying", "error");
            return;
        }
        copyText(current.message as string, () => {
            toast("Copy successful!");
            resetState();
        });
    };
    // 重置当前组件状态
    const resetState = () => {
        dispatch(setSelectState(false));
        dispatch(deleteMessageModalHidden());
    };
    const showModal = () => {
        dispatch(deleteMessageModalShow());
    };
    const showQuote = () => {
        return +conversationType !== ConversationType.Room;
    };
    const showDeleteIcon = () => {
        if (+conversationType !== ConversationType.Room) return true;
        return selfInfo.memberRole === GroupMemberRole.Admin;
    };
    if (!isSelect) return null;
    return <div className="selectBar">
        <div className="selectBar-content">
            <div className="selectBar-left">
                <Back className="navbar-backIcon" onClick={ closebar }/>
                <div>{ num }</div>
            </div>
            <div className="selectBar-right">
                {
                    num === 1 &&
                    <>
                        {
                            showQuote() && <Quote onClick={ chooseQuoteMessage } className="icon"/>
                        }
                        <Copy className="icon" onClick={ handlerCopy }/>
                    </>
                }
                {/*单聊和群聊不限制*/ }
                {/*如果是房间，只有管理员显示删除*/ }
                { showDeleteIcon() && <Rubbish className="icon" onClick={ showModal }/> }
            </div>
        </div>
        <DeleteMessageModal/>
    </div>;
}


export default SelectNavbar;
