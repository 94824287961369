import React from "react";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}


function SuccessIcon(props: IconProps) {
    const { color, ...attrs } = props;
    return <span { ...attrs } role="img">
<svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.210938" y="0.970703" width="28" height="28" rx="14" fill="#009919"/>
    <path d="M9.71094 15.3457L12.7109 18.7207L18.7109 11.9707" stroke="white" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
</svg>

    </span>;
}

export default SuccessIcon;
