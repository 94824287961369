import { Mask } from "antd-mobile";
import cls from "./style.module.scss";
import { Back, DownLoad } from "@/components/themeSvg";
import { downLoadByUrl, GetContainer, mergeProps, renderToContainer } from "@chat/shared";
import { replaceHttpToHttps } from "@chat/shared";
import { toast } from "@/utils";

export type ImageViewerProps = {
    image?: string;
    getContainer?: GetContainer
    onClose?: () => void;
    afterClose?: () => void;
    visible?: boolean
};
const defaultProps = {
    maxZoom: 3,
    getContainer: null,
    visible: false,
};

export function ImageViewer(p: ImageViewerProps) {
    const props = mergeProps(defaultProps, p) as ImageViewerProps;
    const downLoad = () => {
        // 实现下载
        downLoadByUrl(replaceHttpToHttps(props.image || "")).catch(err => {
            console.error(err);
            toast("Download failed", "error");
        });
    };
    const node = (
        <Mask color="rgba(0,0,0,1)" afterClose={ props.afterClose } visible={ props.visible } destroyOnClose
              disableBodyScroll={ false }>
            <div className={ cls.content }>
                <div className={ cls.header }>
                    <Back onClick={ props.onClose } theme="--whats-white" className={ cls.icon }/>
                    <DownLoad theme="--whats-white" className={ cls.icon } onClick={ downLoad }/>
                </div>
                {
                    props.image && (<div className={ cls.image__warp }>
                        <img src={ props.image } alt="" draggable="false"/>
                    </div>)
                }
            </div>
        </Mask>
    );
    return renderToContainer(props.getContainer, node);
}
