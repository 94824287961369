import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable;
}

function ThemeIcon(props: IconProps) {
    const { theme, ...attrs } = props;
    const [color] = useThemeColor(theme);

    return <span role="img" { ...attrs }>
    <svg viewBox="0 0 24 24" fill="none" color={ color }>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12.0008 19.599C16.1983 19.599 19.6011 16.1962 19.6011 11.9987C19.6011 7.80111 16.1983 4.39832 12.0008 4.39832C7.80319 4.39832 4.40039 7.80111 4.40039 11.9987C4.40039 16.1962 7.80319 19.599 12.0008 19.599ZM12.0008 21.599C17.3029 21.599 21.6011 17.3008 21.6011 11.9987C21.6011 6.69654 17.3029 2.39832 12.0008 2.39832C6.69862 2.39832 2.40039 6.69654 2.40039 11.9987C2.40039 17.3008 6.69862 21.599 12.0008 21.599Z"
              fill="currentColor"/>
        <ellipse cx="9.23181" cy="9.92013" rx="1.29236" ry="1.61545" fill="currentColor"/>
        <ellipse cx="14.7709" cy="9.92013" rx="1.29236" ry="1.61545" fill="currentColor"/>
        <path
            d="M14.9548 14.4612C14.9548 15.1862 13.6323 16.4305 12.0008 16.4305C10.3694 16.4305 9.04688 15.1862 9.04688 14.4612C9.04688 13.7361 10.3694 13.8458 12.0008 13.8458C13.6323 13.8458 14.9548 13.7361 14.9548 14.4612Z"
            fill="currentColor"/>
    </svg>

    </span>;
}

export default memo(ThemeIcon);
