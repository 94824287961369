import { useUserInfo } from "@/hooks/useUserInfo";
import { CustomType, ZIMCustomMessage } from "@chat/zim-sdk";
import gameIcon from "@/assets/svg/gameIcon.svg";
import cls from "./lasetMessageStyle.module.scss"

type PicProps = {
    message: ZIMCustomMessage
};

export default function Custom(props: PicProps) {
    const { senderUserID } = props.message;
    const { userInfo } = useUserInfo(senderUserID);
    const customData = {
        image: gameIcon,
        text: props.message.subType === CustomType.Game ? "Bet Slip" : "Customer Card",
    };
    return <div className={ cls.container }>
        <span>{ userInfo?.userName }:</span>
        <img src={ customData.image } className="text-inner-image" alt=""/>
        {
            customData.text
        }
    </div>;
}
