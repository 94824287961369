import { getBaseUrlById, type TypeResponse } from "@/apis/http";
import store2 from "store2";

const apis = {
    getUserStatistics: "/user/getUserStatistics", // 获取当前用户最近游戏信息
    // 获取充值渠道
    payChannel: "/pay/type/active/list",
    // 获取可以充值金额列表
    balanceList: "/pay/balance/list",
    // 充值im埋点
    income: "/pay/income",
};



// 获取当前用户最近的游戏列表
export const getUserLastGame = (params: { imUserId: string }) => {
    const http = getBaseUrlById(store2.get("packageId"));
    return http.post<any, TypeResponse<any>>(apis.getUserStatistics, params);
};

// 渠道
export const getPayChannelList = () => {
    const http = getBaseUrlById(store2.get("packageId"));
    return http.post<any, TypeResponse<any>>(apis.payChannel, {
        channelUser: 0,
        channel: "h5",
    });
};
// 获取金额列表
export const getBalanceList = () => {
    const http = getBaseUrlById(store2.get("packageId"));
    return http.post<any, TypeResponse<any>>(apis.balanceList);
};

export const recharge = (params: any) => {
    const http = getBaseUrlById(store2.get("packageId"));
    return http.post<any, TypeResponse<any>>(apis.income, params);
};
