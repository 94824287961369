import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor();

    return <span role="img" { ...props }>
<svg viewBox="0 0 20 20" color={ color } fill="none">
  <path
      d="M6.85202 4.67273L2.9776 9.03145M2.9776 9.03145L6.85202 13.3902M2.9776 9.03145H13.148C15.2877 9.03145 17.0224 10.7661 17.0224 12.9059V15.3274"
      stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    </span>;
}

export default memo(ThemeIcon);
