import { Mask, MaskProps } from "antd-mobile";
import { Success, Error } from "@/components/normalSvg";
import { ReactNode } from "react";

export type ToastType = "success" | "error";
export type ToastProps = {
    type?: ToastType;
    content?: ReactNode;
    duration?: number;
    visible?: boolean;
    getContainer?: MaskProps["getContainer"];
    afterClose?: () => void
}

function getIcon(type: ToastType) {
    const icons: Record<ToastType, ReactNode> = {
        error: <Error className="icon"/>,
        success: <Success className="icon"/>
    };
    return icons[type] || <Error/>;
}

export function InternalToast(props: ToastProps) {
    const { type = "error", content } = props;
    return <Mask
        visible={ props.visible }
        style={ { zIndex: 9999 } }
        afterClose={ props.afterClose }
        opacity={ 0.1 }>
        <div className="component-toast">
            { getIcon(type) }
            {/*<Image className="icon" src={ getIcon(type) }></Image>*/ }
            <div className="text">{ content }</div>
        </div>
    </Mask>;
}

export default InternalToast;
