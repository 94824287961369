import { Image } from "antd-mobile";
import { setSettingVisible } from "@/store/slice/chat";
import defaultChat from "@/assets/svg/defalt-chat.svg";
import { useAppDispatch, useAppSelector } from "@/store";
import { useScroll } from "ahooks";
import { Back } from "@/components/themeSvg";

export default function Header() {
    const dispatch = useAppDispatch();
    const scroll = useScroll(document.querySelector("#setting-modal"));
    const { groupInfo } = useAppSelector(state => state.chat);
    return <div className="group-header"
                style={ { transform: `translateY(${ scroll && scroll.top > 160 ? 0 : -100 }px)` } }
    >

        <Back onClick={ () => dispatch(setSettingVisible(false)) }
              className="group-header-back"></Back>
        <div className="group-header-title">
            <Image className="group-header-title-icon" src={ groupInfo?.groupLogo }
                   fallback={ <Image src={ defaultChat }/> }></Image>
            <div className="group-header-content">
                <div className="group-header-title-text">{ groupInfo?.groupName }</div>
                {/*<div className="group-header-title-member">*/ }
                {/*    Group <Image style={ { margin: "0 8px" } } src={ dot } width={ 2 }*/ }
                {/*                 height={ 2 }/> { memberList.length } participants*/ }
                {/*</div>*/ }
            </div>

        </div>
    </div>;
}
