import colorRedBall from "@/assets/game-pic/color/color-red.webp";
import redBall from "@/assets/game-pic/color/red.webp";
import violetBall from "@/assets/game-pic/color/color-violet.webp";
import greenBall from "@/assets/game-pic/color/color-green.webp";
import greenFullBall from "@/assets/game-pic/color/green.webp";
import greenVioletBall from "@/assets/game-pic/color/color-green-violet.webp";
import redVioletBall from "@/assets/game-pic/color/color-red-violet.webp";
import { isNumber } from "@chat/shared";
import React from "react";

function nomalizeBall(text: string) {
    if (text === "r") {
        return {
            icon: redBall,
            text: "RED"
        };
    }
    if (text === "v") {
        return {
            icon: violetBall,
            text: "VIOLET"
        };
    }
    if (text === "g") {
        return {
            icon: greenFullBall,
            text: "GREEN"
        };
    }
    let num = parseInt(text);
    if (num === 5) {
        return {
            icon: greenVioletBall,
            text
        };
    }
    if (num === 0) {
        return {
            icon: redVioletBall,
            text
        };
    }
    // 奇数
    if (num % 2) {
        return {
            icon: greenBall,
            text
        };
    }
    return {
        icon: colorRedBall,
        text
    };
}

const ReflexColor = (props: { type: string | number }) => {
    // type
    // 通过不同的text渲染不同的球 0 特殊
    const { type } = props;
    const ballObj = nomalizeBall(type as string);
    const scale = isNumber(ballObj.text) ? 10 / 12 : 5 / 12;
    return <div className="reflex-container">
        <div className="reflex">
            <div className="reflex-item" style={ {
                backgroundImage: `url(${ ballObj.icon })`,
                backgroundSize: "100% 100%",
                color: isNumber(type) ? "#000" : "#fff",
                fontSize: 12
            } }>
                <span style={ { transform: `scale(${ scale })`, whiteSpace: "nowrap" } }>{ ballObj.text }</span>
            </div>
        </div>

    </div>;
};

export default React.memo(ReflexColor);
