import React from "react";
import { useAppSelector } from "@/store";
import classNames from "classnames";
import voiceIcon from "@/assets/svg/voice-message.svg";
import { Ellipsis } from "antd-mobile";
import ShareCards from "@/pages/Chat/feature/ShareCards";
import { emitter } from "@/utils";
import { getVmSize, formattedTime } from "@chat/shared";
import MessageState from "@/pages/Chat/feature/MessageItem/MessageState";
import { Photo, Video } from "@/components/themeSvg";
import cardIcon from "@/assets/svg/card.svg";
import useSelfMessage from "@/pages/Chat/feature/MessageItem/useSelfMessage";
import {
    ChatMessageItem, CustomType,
    MessageType,
    ZIMAudioMessage, ZIMCustomMessage,
    ZIMMessage,
    ZIMVideoMessage
} from "@chat/zim-sdk";
import { useUserInfo } from "@/hooks/useUserInfo";

type QuoteMessage = {
    data: ChatMessageItem
}
// eslint-disable-next-line
export default function QuoteMessage(props: QuoteMessage) {
    const { list } = useAppSelector(state => state.chat);
    const { data } = props;
    const { userInfo } = useUserInfo(data.senderUserID);
    const isSelf = useSelfMessage(data);
    const quotoMessage = data.repliedInfo;
    const content = data.message;
    // 判断是否是自己的信息
    const gotoIndex = () => {
        const currentIndex = list.findIndex(item => item.messageID === quotoMessage?.messageID);
        if (~currentIndex) {
            emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "auto", index: currentIndex });
        }
    };

    const renderContent = () => {
        switch (quotoMessage?.messageInfo.type) {
            case MessageType.Text:
                return <Ellipsis style={ { lineHeight: getVmSize(15) } }
                                 content={ (quotoMessage.messageInfo as ZIMMessage).message as string }
                                 rows={ 3 }></Ellipsis>;
            case MessageType.Video:
                const videoMessage = (quotoMessage.messageInfo as ZIMVideoMessage);
                const videoTime = formattedTime(videoMessage.videoDuration * 1000).join(":");
                return <div className="default-message-content">
                    <Video className="icon"/>
                    {/*<img className="icon" src={ voiceIcon } alt=""/>*/ }
                    <span>Video ({ videoTime })</span>
                </div>;
            case MessageType.Audio:
                const audioMessage = (quotoMessage.messageInfo as ZIMAudioMessage);
                const time = formattedTime(audioMessage.audioDuration * 1000).join(":");
                return <div className="default-message-content">
                    <img className="icon" src={ voiceIcon } alt=""/>
                    <span>Voice ({ time })</span>
                </div>;
            case MessageType.Image:
                return <div className="default-message-content">
                    <Photo className="icon"/>
                    <span>Photo</span>
                    {/*<Image width={ 100 } src={ quotoMessage.pictureElem.sourcePicture.url }></Image>*/ }
                </div>;
            case MessageType.Custom:
                const customMessage = (quotoMessage.messageInfo as ZIMCustomMessage);
                if (customMessage.subType === CustomType.Card) {
                    return <div className="default-message-content">
                        <img src={ cardIcon } alt="" className="icon"/>
                        <span>card</span>
                    </div>;
                }
                return <ShareCards self={ isSelf } data={ customMessage as any } quote={ true }></ShareCards>;
            default:
                return "error quote type";
        }
    };
    return <>
        <div className={ classNames("quote-message", { isSelf }) } onClick={ gotoIndex }>
            <div className="quote-message-name">{ isSelf ? "YOU" : userInfo?.userName }</div>
            <div className="quote-message-content">{ renderContent() }</div>
        </div>
        <div className="quote-message-quoteText">
            <span>
                { content }
            </span>
            <MessageState data={ data } className="message-state__right"/>
        </div>

    </>;
}
