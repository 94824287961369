import { useNavigate as useNavigateHook } from "react-router-dom";
export default function useNavigate() {
    const navigateTo = useNavigateHook();
    const navigate: ReturnType<typeof useNavigateHook> = (path,options?) => {
        navigateTo(path as any, {
            ...(options ? options : {}),
            replace: true,
        });
    }
    return navigate;
}
