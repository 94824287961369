import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable;
}

function ThemeIcon(props: IconProps) {
    const { theme, ...attrs } = props;
    const [color] = useThemeColor("--whats-color-t3");
    return <span role="img" { ...attrs }>
<svg viewBox="0 0 15 15" fill="none">
    <rect x="0.5" y="0.691406" width="14" height="14" rx="2" fill={ color }/>
    <path
        d="M8.57238 7.405L6.72531 9.92334L5.21677 8.63033C4.99568 8.44082 4.66007 8.47959 4.488 8.71451L3.11893 10.5837C2.877 10.914 3.11288 11.3791 3.5223 11.3791H11.5183C11.9375 11.3791 12.1706 10.8941 11.9087 10.5668L9.366 7.38836C9.16043 7.13141 8.767 7.13966 8.57238 7.405Z"
        fill="white"/>
</svg>
    </span>;
}

export default memo(ThemeIcon);

