import { CustomConversationItem } from "@/store/slice/home";
import {
    GroupMemberRole,
    MessageType,
    MessageTypeValue,
    NotificationStatus,
} from "@chat/zim-sdk";
import store from "@/store";
import { UserGroupItem } from "@/apis/room";
import store2 from "store2";

// 获取未读数量，如果没有传入列表则使用内存里的
export function getVisibleUnReadCount(list?: CustomConversationItem[]) {
    const visibleList: CustomConversationItem[] = list ?? store.getState().home.list;
    return visibleList.reduce((total, item) => {
        if (item.conversationID && item.notificationStatus === NotificationStatus.Notify) {
            return total + (item.unreadMessageCount || 0);
        }
        return total;
    }, 0);
}

// 处理用户数据
export const getGroupUserInfo = (data: UserGroupItem, userID?: string) => {
    const info = {
        userID: userID || store2.get("userID"),
        muteExpiredTime: 0, // 禁言时间戳
        block: false, // 拉黑
        remark: "", // 备注
        memberRole: data.ifAdmin ? GroupMemberRole.Admin : GroupMemberRole.Nomal,
        blockId: "",
        muteId: ""
    };
    data.imGroupOperateVos.forEach(item => {
        if (item.operateType === 1) {
            info.muteExpiredTime = item.endTime;
            info.muteId = item.id;
        } else if (+item.operateType === 2) {
            info.block = true;
            info.blockId = item.id;
        }
    });
    return info;
};
// 处理room的历史消息
export const normalizeHistoryMessage = (data: any) => {
    const baseInfo = {
        conversationID: data.conv_id,
        messageID: data.msg_id,
        message: data.msg_body,
        type: +data.msg_type,
        subType: +data.sub_msg_type,
        timestamp: +data.timestamp * 1000,
        senderUserID: data.from_user_id,
    };
    if ([MessageType.Video, MessageType.Audio, MessageType.Image].includes(+data.msg_type as MessageTypeValue)) {
        // media_duration
        const mediaMessage = JSON.parse(data.msg_body);
        const message = {
            localMessageID: data.conv_id,
            fileDownloadUrl: mediaMessage.download_url,
            thumbnailDownloadUrl: mediaMessage.thumbnail_download_url,
            largeImageDownloadUrl: mediaMessage.thumbnail_download_url,
            audioDuration: +mediaMessage.media_duration,
            videoDuration: +mediaMessage.media_duration,
        };
        return {
            ...baseInfo,
            ...message
        };
    }
    return baseInfo;
};
