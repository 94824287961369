import { useEffect, useState } from "react";
import { IUserInfo, userMap } from "@chat/zim-sdk";
import { replaceOssImage } from "@chat/shared";

export function useUserInfo(userID?: string) {
    const [userInfo, setUserInfo] = useState<IUserInfo>();
    useEffect(() => {
        if (userID) {
            const updateUser = (user: IUserInfo) => {
                if (user.userID === userID) {
                    setUserInfo({
                        ...user,
                        userAvatarUrl: replaceOssImage(user.userAvatarUrl)
                    });
                }
            };
            userMap.getUser(userID, false).then(user => {
                setUserInfo({
                    ...user.user,
                    userAvatarUrl: replaceOssImage(user.user.userAvatarUrl)
                });
                userMap.on("UPDATE_USER", updateUser);
            });
            return () => {
                userMap.off("UPDATE_USER", updateUser);
            };
        }

    }, [userID]);


    return {
        userInfo
    };
}
