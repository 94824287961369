import MessageList from "@/pages/Home/features/MessageList";
import WelcomeModal from "@/pages/Home/features/WelcomeModal";
import { useAppSelector } from "@/store";
import PasswordModal from "@/pages/Home/features/PasswordModal";
import "./home.scss";
import useHomeInit from "@/pages/Home/features/hooks/useHomeInit";
import TonMessage from "@/layout/TonMessage"


function Home() {
    useHomeInit();
    const { list, goChatFirst, loading } = useAppSelector(state => state.home);
    const renderHtml = () => {
        if (loading) {
            return <TonMessage></TonMessage>
        }
        if (list && list.length > 0 && !goChatFirst) return <>
            <div className="home">
                <MessageList></MessageList>
            </div>
            <WelcomeModal/>
            <PasswordModal/>
        </>;
    };
    return (
        <>
            {
                renderHtml()
            }
        </>
    );
}

export default Home;

