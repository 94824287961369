import { ImageViewerProps, ImageViewer } from "./image-viewer";
import { renderImperatively } from "@chat/shared";

export type ImageViewerShowHandler = {
    close: () => void
}
const handlerSet = new Set<ImageViewerShowHandler>();

export function showImageViewer(props: Omit<ImageViewerProps, "visible">) {
    clearImageViewer();
    const handler: ImageViewerShowHandler = renderImperatively(
        <ImageViewer { ...props } afterClose={ () => {
            handlerSet.delete(handler);
            props.afterClose?.();
        } }/>
    );
    handlerSet.add(handler);
    return handler;
}

export function clearImageViewer() {
    handlerSet.forEach(handlerSet => handlerSet.close());
    handlerSet.clear();
}
