import React, { useEffect, useMemo, useRef, useState } from "react";
import { DotLoading } from "antd-mobile";
import { ChatMessageItem, SystemMessageTypes } from "@chat/zim-sdk";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import ChatItem from "@/pages/Chat/feature/ChatItem";
import { emitter } from "@/utils";
import { useLatest, useThrottleFn } from "ahooks";
import SystemNotification from "@/pages/Chat/feature/SystemNotification";
import classNames from "classnames";
import { useAppSelector } from "@/store";
import useMarkRead from "@/pages/Chat/feature/hooks/useMarkRead";
import { AnimatePresence, motion } from "framer-motion";
import { ToBottom } from "@/components/themeSvg";

function ChatMain() {
    const [markRead] = useMarkRead();
    const { notReadNum, list, loading } = useAppSelector(state => state.chat);
    const theme = useAppSelector(state => state.common.theme);
    const latestList = useLatest(list);
    const [bottomBtn, setBottomBtn] = useState(false);
    const handleToBottom = (config?: { behavior?: "smooth" | "auto", index?: number }) => {
        const { behavior = "auto", index } = config || {};
        setTimeout(() => {
            virtualRef.current?.scrollToIndex({
                index: index ? index : latestList.current.length - 1,
                behavior
            });
            // 标记已读
            markRead();
        }, 50);
    };
    // 监听到数据更新
    const addMessage = () => {
        if (!bottomBtn) {
            handleToBottom({ behavior: 'smooth' });
        }
    };
    const virtualRef = useRef<VirtuosoHandle>(null);
    const warpRef = useRef<HTMLDivElement>(null);

    const remindMessageNum = useMemo(() => {
        return notReadNum > 99 ? "99+" : notReadNum;
    }, [notReadNum]);
    const rowRenderer = (_: any, data: ChatMessageItem) => {
        if (SystemMessageTypes.includes(data.type as any)) {
            return <SystemNotification data={ data } key={ data.messageID }/>;
        }
        return <ChatItem data={ data } key={ data.messageID } index={ _ }></ChatItem>;
    };
    // 导航栏滚动到最底部显示按钮
    const { run: handleScroll } = useThrottleFn((newItem = false) => {
        const target = document.querySelector("#virtual") as HTMLDivElement;
        const limitValue = 20;
        if (!target) return;
        const scrolltop = target.scrollTop;
        const offsetHeight = target.offsetHeight;
        const scrollHeight = target.scrollHeight;
        if (scrolltop + offsetHeight + limitValue < scrollHeight) {
            if (bottomBtn) return;
            setBottomBtn(true);
        } else {
            if (newItem) {
                if (notReadNum) {
                    markRead();
                }
            }
            if (!bottomBtn) return;
            // 标记已读
            if (notReadNum) {
                markRead();
            }
            setBottomBtn(false);
        }
    }, { wait: 200 });
    useEffect(() => {
        emitter.on("CHAT_LIST_SCROLL_TO_BOTTOM", handleToBottom);
        emitter.on("CHAT_LIST_SCROLL_BY_SHOW_BTN", addMessage);
        return () => {
            emitter.off("CHAT_LIST_SCROLL_TO_BOTTOM", handleToBottom);
            emitter.off("CHAT_LIST_SCROLL_BY_SHOW_BTN", addMessage);
        };
    }, [addMessage]);
    useEffect(() => {
        handleScroll();
    }, [list]);
    return <div className="chat-main-container">
        <div className={ classNames("chat-main-inner") } ref={ warpRef }>
            {
                loading ? <div className="loading">
                    <DotLoading color={ theme.value["--whats-base-color"] }/>
                    <span>Loading</span>
                </div> : <Virtuoso
                    ref={ virtualRef }
                    data={ list }
                    initialTopMostItemIndex={ list.length - 1 }
                    id="virtual"
                    increaseViewportBy={ 600 }
                    computeItemKey={ (_, item) => item.messageID }
                    totalCount={ list.length }
                    onScroll={ handleScroll }
                    itemContent={ rowRenderer }
                ></Virtuoso>
            }

            <AnimatePresence>
                {
                    bottomBtn && <motion.div exit={ { opacity: 0, y: -20 } }
                                             initial={ { opacity: 0, y: 20 } }
                                             animate={ { opacity: 1, y: 0 } }
                                             onClick={ () => handleToBottom() }>
                        <ToBottom className="newMessageCount">
                            { remindMessageNum }
                        </ToBottom>
                    </motion.div>
                }

            </AnimatePresence>
        </div>


    </div>;
}

export default ChatMain;
