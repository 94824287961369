import React, { memo } from "react";
import { useAppSelector } from "@/store";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const { value } = useAppSelector(state => state.common.theme);

    return <span role="img" { ...props }>
         <svg viewBox="0 0 12 13" color={ value["--whats-color-t3"] }>
            <path d="M9 3.19141L3 9.19141" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 3.19141L9 9.19141" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </span>;
}

export default memo(ThemeIcon);
