import lightIcon from "@/assets/svg/light.svg";
import darkIcon from "@/assets/svg/dark.svg";
import { initTheme } from "@/theme";
import React from "react";
import { useAppSelector } from "@/store";

function ThemeBtn() {
    const { name } = useAppSelector(state => state.common.theme);
    return <img src={ name === "dark" ? lightIcon : darkIcon } className="handler-icon" alt=""
                onClick={ () => initTheme(name === "dark" ? "light" : "dark") }/>;
}

export default React.memo(ThemeBtn);
