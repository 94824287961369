import { useMemo, useState } from "react";
import SettingModal from "@/components/SettingModal";
import { useAppDispatch, useAppSelector } from "@/store";
import { getUserByGroupId, setMutedVisible } from "@/store/slice/personal";
import classNames from "classnames";
import { toast } from "@/utils";
import { useCustomMessage } from "@/hooks/useCustomMessage";
import { useParams } from "react-router-dom";
const isProd = process.env.ENV_FILE === "prod"
const mutedList = [
    {
        label: <><span>24</span> <span>hours</span></>,
        value: isProd ? 24 * 60 * 60 : 60,
    },
    {
        label: <><span>3</span> <span>days</span></>,
        value: 3 * 24 * 60 * 60
    },
    {
        label: <><span>7</span> <span>days</span></>,
        value: 7 * 24 * 60 * 60
    },
    {
        label: <><span>Forever</span></>,
        value: 365 * 24 * 60 * 60
    }
];

function MuteModal() {
    const { mutedVisible, user, userId } = useAppSelector(state => state.personal);
    const { conversationID } = useParams();
    const [active, setActive] = useState<number>(0);
    const { sendMuteFlag } = useCustomMessage();
    const dispatch = useAppDispatch();
    const cancel = () => {
        reset();
    };
    const onConfirm = () => {
        // 禁言
        sendMuteFlag(conversationID!, userId, mutedList[active].value * 1000).then(res => {
            dispatch(getUserByGroupId());
            toast("You have muted this user");
            reset();
        });
    };
    const reset = () => {
        dispatch(setMutedVisible(false));
        setActive(0);
    };
    const title = useMemo(() => {
        return `Mute ${ user.userName }?`;
    }, [user]);
    return <SettingModal
        title={ title }
        confirmText="Mute"
        visible={ mutedVisible }
        cancel={ cancel }
        confirm={ onConfirm }
    >
        <div>
            Users who are muted will be unable to send messages for a certain period of time.
        </div>
        <div className="setting-tabs">
            {
                mutedList.map((item, index) => {
                    return <div className={ classNames("setting-tab", { active: active === index }) }
                                key={ item.value }
                                onClick={ () => setActive(index) }
                    >{ item.label }</div>;
                })
            }
        </div>
    </SettingModal>;
}

export default MuteModal;
