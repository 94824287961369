import axios from "axios";
import store2 from "store2";
import { sendLoginMessage, toast } from "@/utils";
import { config } from "@chat/shared";
const env = process.env.ENV_FILE
export const enum TOKEN_TYPE {
    ORIGIN = "originToken",
    CHAT = "chatToken"
}

export type TypeResponse<T = any> = {
    code: number;
    msg: string;
    message:string;
    status: number;
    data: T
}
const defaultData: any = {
    channel: "h5",
    // channelId: "singam",
    lang: 'en_US',
    packageId: 3,
    packageID: 3,
};
const header = {
    'Content-Type': 'application/json',
    packageId: defaultData.packageId,
};
export const createHttp = (baseURL: string, token = TOKEN_TYPE.ORIGIN) => {
    const http = axios.create({
        baseURL,
        timeout: 10000,
        headers: header
    });
    http.interceptors.request.use(
        config => {
            defaultData.packageId = store2.get("packageId") + "";
            defaultData.packageID = store2.get("packageId") + "";
            if (store2.get(token)) {
                config.headers.Token = store2.get(token);
            }
            config.headers.packageId = store2.get("packageId");
            config.data = {
                ...defaultData,
                ...config.data,
                reqDate: Date.now(),
            };
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    http.interceptors.response.use(
        (res) => {
            if (res.status === 200) {
                switch (res.data.code) {
                    case -1:
                        toast(res.data.msg || res.data.message, "error", () => {
                            sendLoginMessage();
                        });
                        return Promise.reject(res.data);
                    default:
                        return Promise.resolve(res.data);
                }
            }
            return Promise.reject(res);
        },
        (error) => {
            switch (error.code) {
                case "ECONNABORTED":
                    console.log("timeout");
                    break;
            }
            toast(error.message, "error");
            return Promise.reject(error);
        },
    );
    return http;
};

export const getBaseUrlById = (packageId: number | string) => {
    const urlMap = config.url;
    const baseUrl = urlMap.get(+packageId) || urlMap.get(3);
    if (env === "test") {
        return createHttp(process.env.REACT_APP_BASE_URL!, TOKEN_TYPE.ORIGIN);
    }
    return createHttp(baseUrl!, TOKEN_TYPE.ORIGIN);
};
