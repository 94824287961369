import { Mask } from "antd-mobile";
import lightIcon from "@/assets/circle-loading.png";
import darkIcon from "@/assets/circle-loading-dark.png";
import { useAppSelector } from "@/store";

function CircleLoading() {
    const { name } = useAppSelector(state => state.common.theme);
    // @ts-ignore
    return <Mask style={ { '--z-index': 9999 } }>
        <div className="circle-loading">
            <div className="circle-loading-bg">
                <img src={ name === "dark" ? darkIcon : lightIcon } alt="" className="circle-loading-bg-logo"/>
            </div>
        </div>
    </Mask>;
}

export default CircleLoading;
