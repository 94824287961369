import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor();

    return <span role="img" { ...props }>
    <svg viewBox="0 0 25 24" fill="none" color={ color }>
    <path
        d="M9.22142 3.98098V7.64816V11.3153L7.40674 13.7349H12.1258M12.1258 13.7349L16.6639 13.7349L14.8492 11.3153V3.98096M12.1258 13.7349V20.6234M8.27344 3.37598H15.7969"
        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <g filter="url(#filter0_d_5978_16776)">
        <path d="M3.43457 4.71289L19.2809 20.5593" stroke="#1B1B1B" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
    </g>
    <defs>
        <filter id="filter0_d_5978_16776" x="2.43457" y="1.71289" width="17.8462" height="19.8467"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset dy="-2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5978_16776"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5978_16776" result="shape"/>
        </filter>
    </defs>
</svg>


    </span>;
}

export default memo(ThemeIcon);
