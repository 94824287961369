import { useParams } from "react-router-dom";
import { useCallback } from "react";
import { useAppSelector } from "@/store";
import {
    ConversationType,
    clearConversationUnreadMessageCount
} from "@chat/zim-sdk";

function useMarkRead() {
    const params = useParams();
    const { conversationID = "", conversationType = ConversationType.Unknown } = params;
    const { conversationDetail } = useAppSelector(state => state.chat);
    const markRead = useCallback(async () => {
        if (+conversationType !== ConversationType.Peer) return;
        if (!conversationDetail.conversationID) return;
        try {
            await clearConversationUnreadMessageCount(conversationID);
        } catch (err) {
            console.error(JSON.stringify(err));
        }
    }, [conversationID, conversationDetail]);
    // 当用户再底部，退出页面的时候，标记已读，否则不标记
    // useUnmount(() => {
    //     markRead();
    // });
    return [markRead];
}

export default useMarkRead;
