import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor("--whats-color-t3");

    return <span role="img" { ...props }>
<svg  viewBox="0 0 25 24" fill="none" color={color}>
<path
    d="M15.9131 8.81593C15.9131 9.16131 16.0503 9.49254 16.2945 9.73676C16.5387 9.98098 16.87 10.1182 17.2153 10.1182C17.5607 10.1182 17.892 9.98098 18.1362 9.73676C18.3804 9.49254 18.5176 9.16131 18.5176 8.81593C18.5176 8.47055 18.3804 8.13931 18.1362 7.89509C17.892 7.65087 17.5607 7.51367 17.2153 7.51367C16.87 7.51367 16.5387 7.65087 16.2945 7.89509C16.0503 8.13931 15.9131 8.47055 15.9131 8.81593Z"
    fill="currentColor"/>
<rect x="4.06348" y="4.3252" width="17.1992" height="15.2324" rx="2" stroke="currentColor" strokeWidth="2"/>
<path d="M4.41016 15.0845L7.57332 11.9189L11.3446 14.2181L13.813 11.9189L18.6878 15.0845" stroke="currentColor"
      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    </span>;
}


export default memo(ThemeIcon);
