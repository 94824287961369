import dayjs from "dayjs";
import { useAppSelector } from "@/store";
import { Ellipsis } from "antd-mobile";

export default function Notification() {
    const { groupInfo } = useAppSelector(state => state.chat);
    if (!groupInfo?.groupInfo.trim()) {
        return null;
    }
    return <div className="group-detail">
        <Ellipsis className="text" rows={ 3 }
                  expandText={ <span className="group-detail-more">Read more</span> }
                  content={groupInfo?.groupInfo! }/>
        <div className="sub-text">Created by { dayjs(groupInfo?.createTime).format("YYYY/MM/DD HH:mm:ss") }</div>
    </div>;
}
