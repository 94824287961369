import { CenterPopup, NumberKeyboard } from "antd-mobile";
import { ReactElement, useEffect, useRef } from "react";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "@/store";
import {
    hidePassword,
    setPasswordError,
    setPasswordFocused,
    setPasswordValue
} from "@/store/slice/home";
import { hideLoading, showLoading } from "@/store/slice/common";
import { applyPasswordRoom } from "@/apis/room";
import { toast } from "@/utils";
import { useNavigate } from "react-router-dom";
import store2 from "store2";
import { ConversationType } from "@chat/zim-sdk";

const LEN = 6;

// 当前选择的密码房信息
function PasswordModal() {
    const navigate = useNavigate();
    const { visible, value, error, focused, groupID } = useAppSelector(state => state.home.password);
    const dispatch = useAppDispatch();
    const allowed = useRef(false);
    const rootRef = useRef<HTMLDivElement>(null);
    const onFocus = () => {
        dispatch(setPasswordFocused(true));
    };
    const onClose = () => {
        rootRef.current?.blur();
        dispatch(setPasswordFocused(false));
        dispatch(hidePassword());
    };
    const renderCells = () => {
        const cells: ReactElement[] = [];
        const chars = value.split("");
        const caretIndex = chars.length;
        for (let i = 0; i < LEN; i++) {
            cells.push(
                <div className={ classNames("input-cell", {
                    "input-cell-caret": caretIndex === i && focused,
                    "input-cell-caret-focused": caretIndex === i && focused
                }) } key={ i }>{ chars[i] ?? "*" }</div>
            );
            if (cells.length === 3) {
                cells.push(<div className="cell-block" key={ i + "block" }></div>);
            }
        }

        return cells;
    };
    const keyBoardInput = (v: any) => {
        if (value.length < LEN) {
            dispatch(setPasswordValue(value + v));
        }
    };
    const handleFill = async (value: string) => {
        dispatch(showLoading());
        dispatch(setPasswordFocused(false));
        try {
            const res = await applyPasswordRoom({
                groupId: groupID!,
                imUserId: store2.get("userID"),
                groupPass: value
            });
            if (res.code === 0) {
                // 获取会话信息
                // 加入密码房成功
                allowed.current = true;
                dispatch(setPasswordError(false));
                dispatch(hidePassword());
                navigate(`/chat/${ groupID }/${ ConversationType.Room }`);
            } else {
                toast(res.message || res.msg || "Incorrect password, please try again", "error");
                dispatch(setPasswordError(true));
                dispatch(setPasswordValue(""));
            }
            dispatch(hideLoading());
        } catch (err: any) {
            toast(JSON.stringify(err), "error");
            dispatch(hideLoading());
        }
    };
    useEffect(() => {
        if (value.length === LEN) {
            handleFill(value);
        }
    }, [value]);
    return <CenterPopup style={ { "--border-radius": "12px", "--background-color": 'none' } } visible={ visible }
                        onMaskClick={ () => {
                            dispatch(hidePassword());
                        } }>
        <div className="passwordModal">
            <div className="passwordModal-head">Please Enter The Password</div>
            <div className="passwordModal-content">
                <div tabIndex={ 0 } role="button" className="passwordModal-input" onClick={ onFocus }
                     onFocus={ onFocus } ref={ rootRef }
                     onBlur={ () => dispatch(setPasswordFocused(false)) }>
                    {
                        renderCells()
                    }
                </div>
                <div className="input-error" style={ { opacity: error ? 1 : 0 } }>Incorrect password, please try again
                </div>
                <div className="passwordModal-tips">The system will periodically ask you to enter this password to help
                    you remember it.
                </div>
            </div>
        </div>
        <NumberKeyboard visible={ focused }
                        onInput={ keyBoardInput }
                        onDelete={ () => {
                            dispatch(setPasswordValue(value.slice(0, -1)));
                        } }
                        onClose={ onClose }
        />
    </CenterPopup>;
}

export default PasswordModal;
