import { DotLoading, SpinLoading } from "antd-mobile";

type LoadingProps = {
    color?: string
}

function Loading(props: LoadingProps) {
    const { color } = props;
    return <div className="my-loading">
        <div style={ { color } }>
            <DotLoading color="currentColor"/>
            <span>Loading</span>
        </div>
    </div>;
}

function LoadingSpin(props: LoadingProps) {
    const { color } = props;
    return <div className="my-loading">
        <div style={ { color } }>
            <SpinLoading color="currentColor"/>
        </div>
    </div>;
}

export {
    LoadingSpin,
    Loading
};
export default Loading;
