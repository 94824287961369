import { useMatch } from "react-router-dom";
import HomeHeader from "@/layout/Header/HomeHeader";
import ChatHeader from "@/layout/Header/ChatHeader";
import SingleHeader from "@/layout/Header/SingleHeader";
import { ConversationType } from "@chat/zim-sdk";
import { useChatMode } from "@chat/shared";

function Header() {
    const homeRouter = useMatch("/home");
    const chat = useMatch("/chat/:conversationID/:conversationType");
    const { chatMode } = useChatMode();
    if (chatMode === "inside") return null;
    if (homeRouter) {
        return <HomeHeader/>;
    }
    if (chat) {
        const { conversationType = "" } = chat.params;
        // 单聊天
        if (+conversationType === ConversationType.Peer) {
            return <SingleHeader/>;
        }
        return <ChatHeader/>;
    }
    return <></>;
}

export default Header;
