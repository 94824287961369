import React from "react";
import { useAppDispatch } from "@/store";
import { openEndModal, openFollowedModal } from "@/store/slice/followed";
import { CardTypeData } from "@/pages/Chat/feature/ShareCards/index";
import { authLogin, EventType, sendPostMessage } from "@/utils";
import AvatarGroup from "@/components/AvatarGroup";

// 需要根据是否是发送者来判断 显示不同的样式
// 发送者 显示跟随的人数
// 接受者，显示 跟随按钮
type ShareFollowedProps = {
    self: boolean;
    data: CardTypeData;
    isEnd: boolean;
}

function ShareFollowed(props: ShareFollowedProps) {
    const { self, data, isEnd } = props;
    const dispatch = useAppDispatch();

    const renderSelf = () => {
        return <div className="followed-self">
            <div className="followed-left">Followed user:</div>
            <AvatarGroup data={ data }/>
        </div>;
    };
    const renderOther = () => {
        // 需要根据不同的类型 展示不同的modal
        const handlerClick = () => {
            // 判断是否是游客，如果是的话，就进入登录
            if (!authLogin()) return;
            if (!data.list) {
                sendPostMessage({
                    event: EventType.PLAY,
                    data
                });
                return;
            }
            if (isEnd) {
                dispatch(openEndModal(data));
            } else {
                dispatch(openFollowedModal(data));
            }
        };
        const text = isEnd ? "Play Now" : "Followed Betting";
        return <div className="followed-other">
            <AvatarGroup data={ data }/>
            <div className="followed-button" onClick={ handlerClick }> { text } </div>
        </div>;
    };
    return <>
        { self ? renderSelf() : renderOther() }
    </>;
}

export default ShareFollowed;
