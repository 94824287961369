import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor();

    return <span role="img" { ...props }>
        <svg viewBox="0 0 21 20" fill="none" color={ color }>
     <rect x="3.37793" y="2.87842" width="14.2441" height="14.2441" rx="5.34155" stroke="currentColor"
           strokeWidth="1.78052"/>
  <circle cx="10.5" cy="7.32971" r="0.890259" fill="currentColor"/>
  <path d="M10.5 10.0005L10.5 13.5615" stroke="currentColor" strokeWidth="1.78052" strokeLinecap="round"/>
        </svg>
    </span>;
}

export default memo(ThemeIcon);
