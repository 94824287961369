import React, { memo } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import { hiddenDismissVisible } from "@/store/slice/common";
import SettingModal from "@/components/SettingModal";
import { fetchConversationList } from "@/store/slice/home";
import { useLocation } from "react-router-dom";

function DismissModal() {
    const visible = useAppSelector(state => state.common.dismissVisible);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const confirm = () => {
        dispatch(hiddenDismissVisible());
        if (location.pathname === "/home") {
            dispatch(fetchConversationList());
        }
    };
    return <SettingModal title="Friendly Reminder"
                         confirm={ confirm }
                         visible={ visible }>
        <div>This group has been disbanded, and you are no longer a member of it</div>
    </SettingModal>;
}

export default memo(DismissModal);
