import { useEffect, useState } from "react";
import { useAppSelector } from "@/store";
import { GroupMemberRole } from "@chat/zim-sdk";
// 判断是否对当前用户进行禁言等权限

export function useAuthMuted(userID: string) {
    const [hasMutedAuth, setHasMutedAuth] = useState(false);
    const { adminList } = useAppSelector(state => state.groupSetting);
    const { selfInfo } = useAppSelector(state => state.chat);
    useEffect(() => {
        setHasMutedAuth(selfInfo.memberRole === GroupMemberRole.Admin && !adminList.includes(userID));
    }, [adminList, userID, selfInfo]);
    return {
        hasMutedAuth
    };
}
