import { useRef, useState } from "react";
import { emitter, toast } from "@/utils";
import { formattedTime } from "@chat/shared";
import { useUnmount } from "ahooks";
import { uid } from "uid";

function useRecorder() {
    const mediaRecorder = useRef<MediaRecorder>();
    const mediaStream = useRef<MediaStream>();
    const mediaAuth = useRef(false);
    const recordedChunks = useRef<Blob[]>([]);
    const timer = useRef<NodeJS.Timer>();
    const [duration, setDuration] = useState(0);
    const [normalizeTime, setNormalizeTime] = useState<(string | number)[]>([0, 0]);
    const refDuration = useRef(0);
    // 获取权限
    const getMedia = async () => {
        // 获取权限
        if (mediaAuth.current) return mediaStream.current;
        try {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                const constraints = { audio: true };
                const res = await navigator.mediaDevices.getUserMedia(constraints);
                mediaStream.current = res;
                mediaAuth.current = true;
                return res;
            } else {
                toast("getUserMedia is doesn't support", "error");
                return Promise.reject(false);
            }
        } catch (err) {
            if (err instanceof DOMException && err.name === "NotAllowedError") {
                toast("Authorization denied", "error");
            } else {
                toast("Failed to access microphone", "error");
            }
            return Promise.reject(false);
        }

    };
    // 创建一个录音器
    const loadVoice = () => {
        if (mediaStream.current) {
            const recorder = new MediaRecorder(mediaStream.current);
            mediaRecorder.current = recorder;
        }
    };
    const startRecord = () => {
        loadVoice();
        const recorder = mediaRecorder.current;
        if (!recorder) return;
        recorder.start();
        timer.current && clearInterval(timer.current);
        const startDate = Date.now();
        const recorderJob = () => {
            const voiceTime = Date.now() - startDate;
            setDuration(voiceTime);
            setNormalizeTime(formattedTime(voiceTime));
            refDuration.current = voiceTime;
        };
        recorderJob();
        timer.current = setInterval(recorderJob, 100);
        recorder.ondataavailable = (e) => {
            recordedChunks.current.push(e.data);
        };
        recorder.onstop = () => {
            const file = new File(recordedChunks.current, uid() + ".mp3");
            recordedChunks.current = [];
            timer.current && clearInterval(timer.current);
            emitter.emit("VOICE_END", {
                file,
                duration: refDuration.current
            });
            closeMedia();
            setDuration(0);
        };
    };
    const stopRecord: () => Promise<{ blob?: Blob, duration: number, file?: File }> = () => {
        return new Promise((resolve, reject) => {
            if (!mediaAuth.current) {
                reject();
            } else {
                mediaRecorder.current?.stop();
                emitter.on("VOICE_END", (data) => {
                    resolve(data);
                    emitter.off("VOICE_END");
                });
            }
        });

    };
    const closeMedia = () => {
        mediaRecorder.current = void 0;
        mediaAuth.current = false;
        if (!mediaStream.current) {
            return;
        }
        const tracks = mediaStream.current.getTracks();
        tracks.forEach(track => {
            track.stop();
        });
        mediaRecorder.current = void 0;
        mediaAuth.current = false;
    };
    // 销毁的时候关闭媒体权限
    useUnmount(() => {
        closeMedia();
    });
    return {
        getMedia,
        loadVoice,
        startRecord,
        stopRecord,
        mediaAuth,
        duration,
        normalizeTime
    };
}

export default useRecorder;
