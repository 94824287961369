import { Image } from "antd-mobile";
import { setSettingVisible } from "@/store/slice/chat";
import defaultChat from "@/assets/svg/defalt-chat.svg";
import { useAppDispatch, useAppSelector } from "@/store";
import { Back, Dot } from "@/components/themeSvg";

export default function Card() {
    const dispatch = useAppDispatch();
    const { groupInfo } = useAppSelector(state => state.chat);
    const { totalCount } = useAppSelector(state => state.groupSetting);
    return <div className="group-card">
        <Back onClick={ () => dispatch(setSettingVisible(false)) } className="back-icon"></Back>
        <Image src={ groupInfo?.groupLogo } fallback={ <Image src={ defaultChat }></Image> }
               className="image"></Image>
        <div className="group-card-title">{ groupInfo?.groupName }</div>
        <div className="group-card-memeber">
            <span>Group</span>
            <Dot style={ { margin: '0 8px', width: "2px", height: "2px", display: "inline-flex" } }/>
            <span>{ totalCount }</span>
            <span style={ { textIndent: '0.2em' } }>Participants</span>
        </div>
    </div>;
}
