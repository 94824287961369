import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor("--whats-color-t3");
    return <span role="img" { ...props }>
<svg viewBox="0 0 17 17" fill="none">
<rect x="3.40039" y="3.19141" width="11" height="11" rx="3.5" stroke={color}/>
<path d="M8.46484 5.74609V9.74609" stroke={color}/>
<path d="M7.97656 9.24609L11.9766 9.24609" stroke={color}/>
</svg>
    </span>;
}

export default memo(ThemeIcon);
