import { useCountDown } from "ahooks";
import React, { memo, useEffect, useRef } from "react";
import { addZero } from "@chat/shared";

type CountTimesProps = {
    remindTime: string | number | Date;
    onEnd: () => void;
    text?: string;
}

function CountTime(props: CountTimesProps) {
    const { remindTime, onEnd } = props;
    const targetDate = useRef(remindTime);
    const [countdown, formattedRes] = useCountDown({
        targetDate: targetDate.current,
        interval: 500,
        onEnd: () => {
            onEnd?.();
        }
    });
    const { minutes, seconds, hours, days } = formattedRes;
    useEffect(() => {
        if (countdown <= 0) {
            onEnd?.();
        }
    }, []);
    const renderTime = () => {
        if (days > 0) {
            return <div className="time">{ days + 'days' }</div>;
        }
        return <>
            <div className="time">{ addZero(hours) + 'h' }</div>
            <span>:</span>
            <div className="time">{ addZero(minutes) + 'm' } </div>
            <span>:</span>
            <div className="time">{ addZero(seconds) + 's' }</div>
        </>;
    };

    return <div className="my-count-time">
        {
            renderTime()
        }
    </div>;
}

export default memo(CountTime);
