import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor("--whats-color-t4");

    return <span role="img" { ...props }>
            <svg viewBox="0 0 8 14" color={ color }>
  <path
      d="M0.6059 6.55133L0.0755703 7.08167L1.13623 8.14233L1.66656 7.612L0.6059 6.55133ZM7.63454 1.64401C7.92743 1.35112 7.92744 0.876247 7.63454 0.583353C7.34165 0.29046 6.86677 0.290461 6.57388 0.583353L7.63454 1.64401ZM1.66656 7.612L7.63454 1.64401L6.57388 0.583353L0.6059 6.55133L1.66656 7.612Z"
      fill="currentColor"/>
  <path
      d="M2.61407 7.49884L2.08374 6.96851L1.02308 8.02917L1.55341 8.5595L2.61407 7.49884ZM6.60589 13.612C6.89878 13.9049 7.37365 13.9049 7.66655 13.612C7.95944 13.3191 7.95944 12.8442 7.66655 12.5513L6.60589 13.612ZM1.55341 8.5595L6.60589 13.612L7.66655 12.5513L2.61407 7.49884L1.55341 8.5595Z"
      fill="currentColor"/>
        </svg>
    </span>;
}

export default memo(ThemeIcon);
