import React, { memo } from "react";
import useBawThemeColor from "@/components/themeSvg/useBawThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useBawThemeColor();
    return <span role="img" { ...props }>
<svg viewBox="0 0 24 25" fill="none">
<g clipPath="url(#clip0_7568_19878)">
<path
    d="M11.8652 13.8984C13.5252 13.8984 14.8552 12.5584 14.8552 10.8984L14.8652 4.89844C14.8652 3.23844 13.5252 1.89844 11.8652 1.89844C10.2052 1.89844 8.86523 3.23844 8.86523 4.89844V10.8984C8.86523 12.5584 10.2052 13.8984 11.8652 13.8984ZM18.0152 10.8984C17.5458 10.8984 17.1731 11.2819 17.0957 11.7449C16.6704 14.2861 14.3575 15.9984 11.8652 15.9984C9.37293 15.9984 7.06002 14.2861 6.6348 11.7449C6.55733 11.2819 6.18468 10.8984 5.71523 10.8984C5.24579 10.8984 4.85935 11.281 4.9218 11.7463C5.32728 14.7668 7.86142 17.1697 10.8652 17.6184V19.8984C10.8652 20.4507 11.313 20.8984 11.8652 20.8984C12.4175 20.8984 12.8652 20.4507 12.8652 19.8984V17.6184C15.8698 17.1787 18.4045 14.7756 18.809 11.7463C18.8711 11.281 18.4847 10.8984 18.0152 10.8984Z"
    fill={ color }/>
</g>
<defs>
<clipPath id="clip0_7568_19878">
<rect width="24" height="24" fill="white" transform="translate(0 0.191406)"/>
</clipPath>
</defs>
</svg>
        </span>;
}

export default memo(ThemeIcon);
