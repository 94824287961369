import { useAppDispatch, useAppSelector } from "@/store";
import {
    closePersonVisible,
    setBlockVisible,
    setCancelBlockVisible,
    setCancelMutedVisible,
    setMutedVisible
} from "@/store/slice/personal";
import { Back, Dots } from "@/components/themeSvg";
import { Action } from 'antd-mobile/es/components/popover';
import { Image, Popover } from "antd-mobile";
import mutedIcon from "@/assets/svg/user-muted.svg";
import blockIcon from "@/assets/svg/block.svg";
import { useAuthMuted } from "@/pages/Chat/feature/hooks/useUserMuted";

export default function Header() {
    const dispatch = useAppDispatch();
    const {
        muteExpiredTime,
        block,
        userID
    } = useAppSelector(state => state.personal.user);
    const { hasMutedAuth } = useAuthMuted(userID);
    const muted = muteExpiredTime > Date.now();
    const actions: Action[] = [
        {
            key: "Unblock",
            icon: blockIcon,
            text: block ? "Unblock" : "To Block",
            onClick: () => {
                if (!block) {
                    dispatch(setBlockVisible(true));
                } else {
                    dispatch(setCancelBlockVisible(true));
                }
            }
        },
        {
            key: "muted",
            icon: mutedIcon,
            text: muted ? "Unmute" : "To Mute",
            onClick: () => {
                if (!muted) {
                    dispatch(setMutedVisible(true));
                } else {
                    dispatch(setCancelMutedVisible(true));
                }
            }
        },
    ];
    const menuContent = <div className="popover-menu-content">
        { actions.map(item => <div key={ item.key } className="menu-item" onClick={ item.onClick }>
            <Image src={ item.icon as any } className="icon"></Image>
            <span>{ item.text }</span>
        </div>) }
    </div>;
    return <div className="header">
        <div className="header-left">
            <Back className="header-back" onClick={ () => dispatch(closePersonVisible()) }></Back>
        </div>
        <div className="header-right">
            {
                hasMutedAuth && <Popover
                    style={ { "--arrow-size": "0px" } }
                    trigger="click"
                    content={ menuContent }
                >
                    <Dots className="header-more"></Dots>
                </Popover>
            }
        </div>
    </div>;
}
