import cls from "./style.module.scss";
import FloatModal from "@/components/FloatModal";
import { useAppDispatch, useAppSelector } from "@/store";
import { Grid, Input, Space } from "antd-mobile";
import { useEffect, useState } from "react";
import { formatPrice } from "@chat/shared";
import _ from "lodash";
import classnames from "classnames";
import { setRechargeValue, setRechargeVisible } from "@/store/slice/chat";
import useRecharge from "@/pages/Chat/feature/hooks/useRecharge";

export default function RechargeModal() {
    const dispatch = useAppDispatch();
    const { toRecharge } = useRecharge();
    const {
        rechargeVisible,
        rechargeValue,
        rechargeBalanceList,
        rechargeChannel
    } = useAppSelector(state => state.chat);
    const [balanceMap, setBalanceMap] = useState<number[][]>([]);
    const [ratio, setRatio] = useState(0);
    useEffect(() => {
        // 每次更新充值金额，获取优惠程度
        const currentRatio = balanceMap.find(item => {
            const [start, end] = item;
            return _.inRange(+rechargeValue, start, end);
        });
        if (currentRatio) {
            setRatio(currentRatio[2]);
        }
    }, [balanceMap, rechargeValue]);
    useEffect(() => {
        const map: number[][] = rechargeBalanceList.reduce((previousValue, currentValue, currentIndex) => {
            const arr = previousValue[previousValue.length - 1] || [];
            if (arr.length === 0) {
                previousValue.push(arr);
                arr.push(0);
                arr.push(currentValue.balance, 0);
            } else {
                const prev = rechargeBalanceList[currentIndex - 1];
                arr.push(currentValue.balance, prev.ratio);
            }
            if (previousValue.length === rechargeBalanceList.length) {
                previousValue.push([currentValue.balance, 99999, currentValue.ratio]);
            } else {
                previousValue.push([currentValue.balance]);
            }
            return previousValue;
        }, [] as number[][]);
        setBalanceMap(map);
    }, [rechargeBalanceList]);
    const handleChange = (val: string) => {
        const value = parseInt(val || "0");
        if (!isNaN(value) && value >= 0 && value <= 99999) {
            dispatch(setRechargeValue(value + "" || '0'));
        }
    };
    const renderExtra = () => {

        const value = +rechargeValue * ratio / 100;
        return <div className={ cls.handler }
                    onClick={ () => toRecharge(rechargeValue) }>Recharge { value ? `(+₹${ formatPrice(value) })` : "" }</div>;
    };
    return <FloatModal visible={ rechargeVisible } contentClass={ cls.rechargeModal } title="Recharge"
                       handler={ renderExtra() }
                       onClose={ () => dispatch(setRechargeVisible(false)) }
    >
        <div className={ cls.input__content }>
            <Input type="number"
                   className={ cls.input }
                   value={ rechargeValue }
                   onChange={ handleChange }
            />
            {
                ratio ? <div className={ cls.extra }>Extra ${ ratio }% bonus</div> : null
            }
        </div>
        <div className={ cls.tips }>
            Please enter in round figure in <span className={ cls.tips__base }>50s</span> and <span
            className={ cls.tips__base }>100s</span>
        </div>
        <div className={ cls.limit }>
            <Space className={ cls.limit__item }>
                <div className={ cls.limit__item__label }>Min</div>
                <div className={ cls.limit__item__value }>₹ { formatPrice(rechargeChannel.minAmount) } </div>
            </Space>
            <Space className={ cls.limit__item }>
                <div className={ cls.limit__item__label }>Min</div>
                <div className={ cls.limit__item__value }>₹ { formatPrice(rechargeChannel.maxAmount) }</div>
            </Space>
        </div>
        <Grid className={ cls.recharge__list } columns={ 4 }>
            {
                rechargeBalanceList.map(item => {
                    return <Grid.Item onClick={ () => dispatch(setRechargeValue(item.balance + "")) }
                                      className={ classnames(cls.recharge__item, +rechargeValue === item.balance && cls.recharge__item__active) }
                                      key={ item.id }>
                        { item.balance }
                        {
                            item.ratio && <div className={ cls.badge }>Extra +{ item.ratio }%</div>
                        }
                    </Grid.Item>;
                })
            }

        </Grid>
    </FloatModal>;
}
