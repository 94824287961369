import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ThemeValue } from "@/theme";
import { getCustomer, IdentityItem } from "@/apis/im";
import _ from "lodash";


export interface IUser {
    userID: string;
    userName: string;
    userAvatarUrl?: string;
    memberNickname?: string;
    memberRole?: number;
    muteExpiredTime?: number;
}

const initialState = {
    lng: "en-US",
    loginModalVisible: false,
    editorModalVisible: false,
    twiceVisible: false,
    loading: false,
    kickVisible: false,
    expiredVisible: false,
    dismissVisible: false,
    full: false,
    identityList: [] as IdentityItem[],
    theme: {
        name: "light",
        value: {}
    } as ThemeValue
};
export const fetchIdentity = createAsyncThunk("common/getIdentity", async (payload, { dispatch }) => {
    const res = await getCustomer();
    const list = _.compact(res.data || []);
    dispatch(setIdentityList(list));
});
export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setLoginModalVisible(state, action) {
            state.loginModalVisible = action.payload;
        },
        setEditorModalVisible(state, action) {
            state.editorModalVisible = action.payload;
        },
        setTwiceVisible(state, action) {
            state.twiceVisible = action.payload;
        },
        setKickVisible(state, action) {
            state.kickVisible = action.payload;
        },
        setExpiredVisible(state, action) {
            state.expiredVisible = action.payload;
        },
        setThemeValue(state, action) {
            state.theme = action.payload;
        },
        showLoading(state) {
            state.loading = true;
        },
        hideLoading(state) {
            state.loading = false;
        },
        setFull(state, action) {
            state.full = action.payload;
        },
        setIdentityList(state, action) {
            state.identityList = action.payload;
        },
        showDismissVisible(state) {
            state.dismissVisible = true;
        },
        hiddenDismissVisible(state) {
            state.dismissVisible = false;
        },
    }
});

export const {
    setLoginModalVisible,
    setEditorModalVisible,
    setTwiceVisible,
    setKickVisible,
    setExpiredVisible,
    showLoading,
    hideLoading,
    setThemeValue,
    setFull,
    setIdentityList,
    showDismissVisible,
    hiddenDismissVisible
} = commonSlice.actions;
export default commonSlice.reducer;
