import type { CardItemItem } from "@/utils";
import ReflexQuickRace from "@/pages/Chat/feature/Reflex/ReflexQuickRace";

type DigitProps = {
    data: CardItemItem[]
}

function Digit(props: DigitProps) {
    const { data } = props;
    return <div className="dice-game">
        {
            data.map(item => {
                return <div className="dice-game-item">
                    <ReflexQuickRace type={ item.number }/>
                    {/*<div className="price">₹{ item.amount.toFixed(2) } </div>*/}
                </div>;
            })
        }

    </div>;
}

export default Digit;
