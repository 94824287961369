import React, { memo } from "react";
import { useAppSelector } from "@/store";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

const themeConfig = {
    light: {
        circleStroke: "#D8FDD2",
        pathFill: "white"
    },
    dark: {
        circleStroke: "#103629",
        pathFill: "#0A151B"
    }
};

function ThemeIcon(props: IconProps) {
    const { name } = useAppSelector(state => state.common.theme);
    const { circleStroke, pathFill } = themeConfig[name as keyof typeof themeConfig];

    return <span role="img" { ...props }>
        <svg viewBox="0 0 24 24" fill="none">
        <circle cx="12" cy="12" r="11" fill="#20C164" stroke={ circleStroke } strokeWidth="2"/>
        <path
            d="M10.8069 15.2202C10.4989 15.5151 10.0133 15.5151 9.70536 15.2202L7.41727 13.0291C7.13673 12.7604 7.13673 12.3121 7.41727 12.0435C7.68115 11.7908 8.09724 11.7908 8.36112 12.0435L9.83842 13.4582C10.072 13.6818 10.4403 13.6818 10.6738 13.4582L15.639 8.70339C15.9029 8.4507 16.319 8.45071 16.5829 8.70341C16.8634 8.97205 16.8634 9.42033 16.5828 9.68896L10.8069 15.2202Z"
            fill={ pathFill }
        />
        </svg>
    </span>;
}

export default memo(ThemeIcon);
