import useNavigate from "@/hooks/useNavigate";
import { useAppSelector } from "@/store";
import { sendTabBarVisible } from "@/utils";
import React, { memo } from "react";
import { Back, IdentityBaw } from "@/components/themeSvg";
import classnames from "classnames";
import { Image, Space } from "antd-mobile";
import { replaceOssImage } from "@chat/shared";
import useIdentity from "@/hooks/useIdentity";

function ChatHeader() {
    const {singleInfo} = useAppSelector(state => state.chat);
    const isSelect = useAppSelector(state => state.chat.isSelect);
    const { isIdentity } = useIdentity(singleInfo.userID);
    const navigate = useNavigate();
    const title = singleInfo.userName;
    const faceUrl = replaceOssImage(singleInfo.userAvatarUrl) + "?x-oss-process=image/resize,m_lfit,w_64";
    const renderTitle = () => {
        return <div className="title">
            <Image src={ faceUrl } className="title-image" placeholder=""></Image>
            <Space direction="vertical" className="title-text">
                <Space className="title-top">
                    { title }
                    {
                        isIdentity && <IdentityBaw className="title-icon"/>
                    }
                </Space>
                {
                    isIdentity &&
                    <span className="title-sub-text">Official Support Account</span>
                }

            </Space>
        </div>;
    };
    const handleBack = () => {
        navigate("/home", { replace: true });
        // if (list.length > 1) {
        //     navigate("/home");
        // } else {
        //     sendBackMessage();
        // }
        sendTabBarVisible(true);
    };
    return <div className="layout-header-single">
        <div className={ classnames("layout-header-single-content", { hideShadow: isSelect }) }>
            <div className="layout-header-single-content__back">
                <Back className="navbar-backIcon" onClick={ handleBack }></Back>
            </div>
            <div className="layout-header-single-content__title">
                {
                    renderTitle()
                }
            </div>
        </div>
    </div>;
}

export default memo(ChatHeader);
;
