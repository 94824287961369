import { login, addEvent, offEvent, logout } from "@chat/zim-sdk";
import { useEffect, useState } from "react";
import { useAppSearchParams } from "@chat/shared";
import store2 from "store2";
import { initTheme } from "@/theme";
import { getImToken } from "@/apis/im";
import { toast } from "@/utils";
import { useAppDispatch } from "@/store";
import { setLogged, updateSelf } from "@/store/slice/user";
import { fetchIdentity, setExpiredVisible } from "@/store/slice/common";

const getVisitorState = (data: any): boolean => {
    if (data) {
        return data === "true";
    }
    return store2.get("isVisitor");
};
// 1.先判断是否是游客，如果是游客不走登录
// 2.不是游客，获取当前的originToken，如果存在就登录，不存在取本地的imtoken，存在就登录。不存在就报错

export function useZimInit() {
    const [errPage, setErrPage] = useState(false);
    const [mounted, setMounted] = useState(false);
    const originToken = useAppSearchParams("originToken") || store2.get("originToken");
    const deviceCode = useAppSearchParams("deviceCode") || store2.get("deviceCode");
    const packageId = useAppSearchParams("packageId") || store2.get("packageId");
    const visitorData = useAppSearchParams("isVisitor");
    const isVisitor = getVisitorState(visitorData);
    const dispatch = useAppDispatch();
    const getUserToken = async () => {
        store2.set("packageId", parseInt(packageId) || 3);
        try {
            if (isVisitor) {
                // 1. 游客状态
                // 3. 登录im
                // 清除老的token
                store2.remove("originToken");
                // 游客不需要登录，走平台的token获取的列表
                return {
                    originToken,
                    isVisitor,
                    deviceCode
                };
            } else {
                // 1.不是游客，读取origintoken，没有的话从内存获取
                store2.set("originToken", originToken);
                // 2. 获取
                const res = await getImToken();
                if (res.code === 0) {
                    return {
                        originToken,
                        isVisitor,
                        deviceCode,
                        imToken: res.data.token,
                        userID: res.data.userID
                    };
                }
                return Promise.reject(res);
            }

        } catch (err) {
            return Promise.reject(err);
        }
    };
    const init = async () => {
        // const imToken = generateToken("DI185420", 0);
        // const userID = "DI185420";
        // const imToken = generateToken("im_002", 0);
        // const userID = "im_002";
        initTheme();
        addEvent({
            getImToken,
            afterRefreshToken(token) {
                store2.set("imToken", token);
            },
            kickedOut: () => {
                dispatch(setExpiredVisible(true))
            }
        });
        const res = await getUserToken().catch(err => {
            toast(err.msg || "something went wrong", "error");
            setErrPage(true);
        });
        if (res) {
            store2.setAll(res);
            const { imToken, userID } = res;
            if (userID && imToken) {
                login(userID, imToken).then(userInfo => {
                    // 更新当前登录用户的信息
                    dispatch(setLogged(true));
                    dispatch(updateSelf(userInfo));
                    // 获取公共客服列表
                    dispatch(fetchIdentity());
                    setMounted(true)
                }).catch(err => {
                    console.error(err);
                });
            } else {
                setMounted(true)
                dispatch(setLogged(false));
                console.log("目前是游客身份>>>>>");
            }

        }

    };
    useEffect(() => {
        init();
        return () => {
            offEvent();
            logout();
        };
    }, []);
    // 登录
    return {
        errPage,
        mounted
    };
}

