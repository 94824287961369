import React from "react";
import FloatModal from "@/components/FloatModal";
import { useAppDispatch, useAppSelector } from "@/store";
import { setFollowedModalVisible, TGameCode } from "@/store/slice/followed";
import ReflexDigit from "@/pages/Chat/feature/Reflex/ReflexDigit";
import classNames from "classnames";
import ReflexColor from "@/pages/Chat/feature/Reflex/ReflexColor";
import ReflexDice from "@/pages/Chat/feature/Reflex/ReflexDice";
import ReflexSatta from "@/pages/Chat/feature/Reflex/ReflexSatta";
import { Image } from "antd-mobile";
import cardCloseIcon from "@/assets/card-close.png";
import { CardItemItem, EventType, sendPostMessage, toast, useListenMessage } from "@/utils";
import ReflexQuickComprehensive from "@/pages/Chat/feature/Reflex/ReflexQuickComprehensive";
import ReflexQuickRace from "@/pages/Chat/feature/Reflex/ReflexQuickRace";
import { useThrottleFn } from "ahooks";

function renderReflex(type: TGameCode, data: CardItemItem) {
    switch (type) {
        case "3Digit":
        case "quick3d":
            return <ReflexDigit type={ data.number }/>;
        case "color":
            return <ReflexColor type={ data.number }/>;
        case "dice":
            return <ReflexDice type={ data.playType } value={ data.number }/>;
        case "satta":
        case "matka":
            return <ReflexSatta flexDirection="column" type={ data.number } value={ data.digits as string }/>;
        case "quickStateLottery":
        case "stateLottery":
            return <ReflexQuickComprehensive type={ data.modeID! } value={ data.number }></ReflexQuickComprehensive>;
        case "quickRace":
            return <ReflexQuickRace type={ data.number }></ReflexQuickRace>;
        default:
            return type;
    }
}

function FollowedModal() {
    const { selfInfo } = useAppSelector(state => state.chat);
    const {
        visible,
        isEnd,
        followedData
    } = useAppSelector(state => state.followed);
    const dispatch = useAppDispatch();
    const gameNote: any[] = [
        {
            name: "Share user：",
            value: followedData.shareName
        },
        {
            name: "Game name：",
            value: followedData.gameName
        },

    ];
    if (isEnd) {
        gameNote.push({
                name: "Total bet：",
                value: "₹" + followedData.totalAmount
            },
            // {
            //     name: "Total winnings:",
            //     value: <span className="winAmount">₹{ followedData.totalAwardAmount || 0 }</span>
            // }
        );
    }

    const { run: followedClick } = useThrottleFn(() => {
        sendPostMessage({
            event: EventType.FOLLOWED,
            data: {
                ...followedData,
                currentUserID: selfInfo.userID
            }
        });
    });
    const playNowClick = () => {
        const data = {
            ...followedData,
            currentUserID: selfInfo.userID
        };
        sendPostMessage({
            event: EventType.PLAY,
            data
        });
    };
    const handlerRender = () => {
        return <div className="followedModal-handler">
            {
                isEnd ? <div className="followed-button" style={ { width: "100%" } } onClick={ playNowClick }>Play
                    Now</div> : <>
                    <div className="total">
                        <div className="total-price">₹{ followedData.totalAmount }</div>
                        <div
                            className="total-number">{ followedData.totalCount || followedData.list.length || 1 } Numbers
                        </div>
                    </div>
                    <div className="followed-button" onClick={ followedClick }>Followed Betting</div>
                </>
            }
        </div>;
    };

    useListenMessage((msg) => {
        if (msg.event === EventType.SUCCESS) {
            toast(msg.data.message || "success!", 'success', () => {
                dispatch(setFollowedModalVisible(false));
            });
            return;
        }
        if (msg.event === EventType.FAIL) {
            toast(msg.data.message || "error!", "error");
        }
    });
    const getHeadTab = () => {
        switch (followedData.gameType) {
            case "3Digit":
            case "quick3d":
                return <div className="cell">
                    <ReflexDigit type="ABC"></ReflexDigit>
                </div>;
            default:
                return <div className="cell">
                    NUMBER
                </div>;
        }
    };
    const needType = () => {
        switch (followedData.gameType) {
            case "stateLottery":
            case "quickStateLottery":
            case "dice":
                return true;
            default:
                return false;
        }
    };
    return <FloatModal
        contentClass="followedContent"
        visible={ visible }
        onClose={ () => dispatch(setFollowedModalVisible(false)) }
        title="Share Note"
        handler={ handlerRender() }
    >
        <div className="note">
            {
                gameNote.map(item => {
                    return <div className="note-item" key={ item.name }>
                        <div className="note-item-name">{ item.name }</div>
                        <div className="note-item-value">{ item.value }</div>
                    </div>;
                })
            }
            {
                isEnd && <Image src={ cardCloseIcon } className="note-close"></Image>
            }
        </div>
        <div className="bets">
            <div className={ classNames("bets-header", followedData.gameType) }>
                {
                    getHeadTab()
                }
                {
                    needType() && <div className="cell">
                        GAME TYPE
                    </div>
                }
                <div className="cell">PAYMENT</div>
                {
                    isEnd ? null : <div className="cell">RESULT</div>
                }
            </div>
            <div className="bets-list">
                {
                    followedData.list?.map((item, index) => {
                        const renderWinCell = () => {
                            if (isEnd) return null;
                            return <div className="cell">
                                {
                                    item.status === 0 ? <>
                                        <div>To be drawn</div>
                                        <div>...</div>
                                    </> : <>
                                        <div>win</div>
                                        <div
                                            className={ item.awardAmount ? "winAmount" : "loseAmount" }>₹{ item.awardAmount?.toFixed(2) || 0 }</div>
                                    </>
                                }
                            </div>;

                        };
                        return <div className="bets-list-item" key={ index }>
                            <div className="cell">
                                {
                                    renderReflex(followedData.gameType, item)
                                }
                            </div>
                            {
                                needType() && <div className="cell">
                                    { item.playType ?? [item.modeName, item.tabName].map(item => {
                                        return <div key={ item }>{ item }</div>;
                                    }) }
                                </div>
                            }
                            <div className="cell payment-item">
                                {
                                    `₹${ item.amount.toFixed(2) }${ item.count ? '*' + item.count : '' }`
                                }
                            </div>
                            {
                                renderWinCell()
                            }
                        </div>;
                    })
                }
            </div>
        </div>
    </FloatModal>;
}

export default FollowedModal;
