import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable;
}

function ThemeIcon(props: IconProps) {
    const { theme, ...attrs } = props;
    const [color] = useThemeColor(theme);

    return <span role="img" { ...attrs }>
<svg viewBox="0 0 25 25" fill="none" color={ color }>
<g id="Frame 1000006166">
<rect id="Rectangle 23374" x="3.24854" y="5.68555" width="17.6994" height="13.6026" rx="3.25" stroke="currentColor"
      strokeWidth="1.5"/>
<path id="Ellipse 996"
      d="M10.1177 10.2685C9.82061 10.1423 9.28418 10.0371 8.94108 10.0371C7.57376 10.0371 6.46533 11.1456 6.46533 12.5129C6.46533 13.8802 7.57376 14.9887 8.94108 14.9887C9.60349 14.9887 10.2051 14.7285 10.6494 14.3048V12.5938L9.68329 12.5938"
      stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector 12" d="M13.147 10.0371L13.147 14.9888" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
<path id="Vector 13"
      d="M15.645 14.9888L15.645 12.513M17.7317 10.0371L15.645 10.0371L15.645 12.513M15.645 12.513L17.7317 12.513"
      stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</svg>


    </span>;
}

export default memo(ThemeIcon);
