import { CardTypeData } from "@/pages/Chat/feature/ShareCards";
import { ChatMessageItem } from "@chat/zim-sdk";
import { authLogin, EventType, sendPostMessage } from "@/utils";
import React, { useMemo } from "react";
import { replaceOssImage } from "@chat/shared";
import AvatarGroup from "@/components/AvatarGroup";
import MessageState from "@/pages/Chat/feature/MessageItem/MessageState";

type LuckMessageProps = {
    self: boolean;
    data: ChatMessageItem;
    quote?:boolean;
    cardData: CardTypeData
}

function LuckMessage(props: LuckMessageProps) {
    const { cardData, self, data, quote } = props;
    const playnow = () => {
        if (!authLogin()) return;
        sendPostMessage({
            event: EventType.PLAY,
            data: {
                ...cardData
            }
        });
    };
    const renderAmount = useMemo(() => {
        return `${ cardData.totalAmount.toFixed(2) }${ cardData.count ? '*' + cardData.count : '' }`;
    }, [cardData.count]);
    return (
        <>
            <div className="luck-message">
                <div className="luck-message-title">
                    <span>Luck is here, come try your luck!</span>
                </div>
                <div className="luck-inner">
                    <div className="luck-message-box">
                        <img className="luck-message-box-icon" src={ replaceOssImage(cardData.gameIconUrl) } alt=""/>
                        <div className="luck-content">
                            <div className="luck-content-title">
                                { cardData.gameName }
                            </div>
                            <div className="luck-content-price">₹{ renderAmount }</div>
                        </div>
                        <div className="luck-won">
                            <div className="won-text">Won</div>
                            <div className="won-price">₹{ cardData.totalAwardAmount?.toFixed(2) || 0.00 }</div>
                        </div>
                    </div>
                    {
                        !self && <>
                            <div className="line"></div>
                            <div className="luck-message-btn">
                                <AvatarGroup data={ cardData }/>
                                <div className="btn" onClick={ playnow }>
                                    <span>Play</span>
                                    <span>Now</span>
                                </div>
                            </div>
                        </>
                    }
                </div>
                {
                    !quote && <MessageState data={ data as any } className="message-state__bottom"/>
                }
            </div>
        </>
    );
}


export default LuckMessage;
