import React from "react";
import MessageState from "@/pages/Chat/feature/MessageItem/MessageState";
import {ZIMTextMessage} from "@chat/zim-sdk"

type TextMessageProps = {
    data: ZIMTextMessage
}
export default function TextMessage(props: TextMessageProps) {
    const { data } = props;
    return<>
        { data.message }
        <MessageState data={ data } className="message-state__right"/>
    </>;
}
