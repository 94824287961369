import { createHttp, TOKEN_TYPE, type TypeResponse } from "./http";

const http = createHttp(process.env.REACT_APP_IM_API_URL!, TOKEN_TYPE.ORIGIN);
const apis = {
    getReqParams: "/zgIm/getReqParams", // 获取imtoken
    // 获取客服
    getCustomer: "/zgIm/customers",
    // 修改修改im的状态
    setCustomer: "/zgIm/updateImStatus",
    // 获取gif
    getGif: "/zgIm/getImEmoticons",
    // 分页获取gif
    getGifPage: "/zgIm/getImEmoticonsPage",
    // 获取在线聊天室列表
    getRoom: "/groupOperate/getList",


    history: "/group/prize/history",
    userFull: "/user/find/full",
    // 为所有人删除， 不走imsdk
    deleteMsgs: "/msg/delete_msgs",
    identity: "/user/identity/list",


};
type ImTokenRes = {
    userID: string;
    token: string;
}
// 获取imtoken
export const getImToken = (deviceCode?: string) => {
    const headers = deviceCode ? {
        deviceCode
    } : {};
    return http.post<any, TypeResponse<ImTokenRes>>(apis.getReqParams, null, {
        headers
    });
};
export type IdentityItem = {
    imCustomer: "1" | "2";
    imUserId: string;
    imUserStatus: string;
    userAvatar: string;
    userId: number;
    userName: string;
}

export const getCustomer = () => {
    // imCustomer 可选 1支付客服 2游戏客服
    return http.get<any, TypeResponse<IdentityItem[]>>(apis.getCustomer);
};
// 获取历史获胜接口
export const getHistoryWin = () => {
    return http.post<any, TypeResponse<any>>(apis.history);
};
// 获取当前用户信息 /user/find/full
export const getUserFull = (params: { userId: string }) => {
    const { userId } = params;
    return http.post<any, TypeResponse<any>>(apis.userFull, {
        userIDs: [userId]
    });
};


// 获取gif图片
export const getGifImage = () => {
    return http.get<any, TypeResponse<any>>(apis.getGif);
};
export const getGifPage = (params: { pageNo: number, pageSize: number }) => {
    return http.post<any, TypeResponse<any>>(apis.getGifPage, params);
};

// 清除指定会话的聊天记录
export const deleteForEveryone = (params: {
    conversationID: string;
    messages: { seqs: number, sendID: string }[]
}) => {
    return http.post<any, TypeResponse<any>>(apis.deleteMsgs, params);
};

