import { Ellipsis, Image, Space } from "antd-mobile";
import React from "react";
import classNames from "classnames";
import {
    MessageType,
    ZIMAudioMessage,
    ZIMCustomMessage,
    ZIMVideoMessage,
    CustomType,
} from "@chat/zim-sdk";
import { useAppDispatch, useAppSelector } from "@/store";
import cardIcon from "@/assets/svg/customCard.svg";
import { setQuoteMessage } from "@/store/slice/chat";
import voiceIcon from "@/assets/svg/voice-message.svg";
import picIcon from "@/assets/svg/photo.svg";
import { formattedTime } from "@chat/shared";
import cls from "./foot.module.scss";
import { Close, Photo, Video as VideoIcon } from "@/components/themeSvg";
import { useUserInfo } from "@/hooks/useUserInfo";

function Quote() {
    const { quoteMessage, selfInfo } = useAppSelector(state => state.chat);
    const dispatch = useAppDispatch();
    const {userInfo} = useUserInfo(quoteMessage?.senderUserID!)
    const isSelf = selfInfo.userID === quoteMessage?.senderUserID;
    const renderContent = () => {
        switch (quoteMessage?.type) {
            // 文本
            case MessageType.Text:
                return <Ellipsis content={ quoteMessage.message as string } rows={ 3 }></Ellipsis>;
            // case MessageType.QuoteMessage:
            //     return <Ellipsis content={ quoteMessage.quoteElem.text } rows={ 1 }></Ellipsis>;
            case MessageType.Image:
                return <Space>
                    <Photo className={ cls.icon }/>
                    <span>Photo</span>
                </Space>;
            case MessageType.Audio:
                const time = formattedTime((quoteMessage as ZIMAudioMessage).audioDuration * 1000).join(":");
                return <Space>
                    <Image className={ cls.icon } src={ voiceIcon }/>
                    <span>Voice Message ({ time })</span>
                </Space>;
            case MessageType.Video:
                const videoTime = formattedTime((quoteMessage as ZIMVideoMessage).videoDuration * 1000).join(":");
                return <Space>
                    <VideoIcon className={cls.icon}/>
                    <span>Video Message ({ videoTime })</span>
                </Space>;
            case MessageType.Custom:
                const subType = (quoteMessage as ZIMCustomMessage).subType;
                if (subType === CustomType.Image) {
                    return <Space>
                        <Image src={ picIcon } className={ cls.icon }></Image>
                        <span>GIF</span>
                    </Space>;
                }
                return <Space>
                    <Image src={ cardIcon } className={ cls.icon }></Image>
                    <span>Bet Slip</span>
                </Space>;
            default:
                return "errorType：" + quoteMessage?.type;
        }
    };

    return quoteMessage ?  <div className={ classNames(cls.replyQuote, { [cls.replyQuote__self]: isSelf }) }>
        <div className={ cls.replyQuote__header }>
            <div className={ cls.replyContent__name }>{ isSelf ? "YOU" :  userInfo?.userName}</div>
            <Close onClick={ () => dispatch(setQuoteMessage(null)) } className={ cls.replyClose }/>
        </div>
        <div className={ cls.replyContent }>
            { renderContent() }
        </div>
    </div> : null;
}

export default Quote;
