import ReflexDigit from "@/pages/Chat/feature/Reflex/ReflexDigit";
import type { CardItemItem } from "@/utils";
type DigitProps = {
    data: CardItemItem[]
}

function Digit(props: DigitProps) {
    const { data } = props;
    return <div className="dice-game">
        {
            data.map((item,i) => {
                return <div className="dice-game-item" key={i}>
                    <ReflexDigit type={ item.number }/>
                    {/*<div className="price">₹{ item.amount.toFixed(2) }*{item.count} </div>*/}
                </div>;
            })
        }

    </div>;
}

export default Digit;
