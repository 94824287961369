import useNavigate from "@/hooks/useNavigate";
import { useAppDispatch, useAppSelector } from "@/store";
import { sendTabBarVisible } from "@/utils";
import React, { memo } from "react";
import { setRuleModalVisible, setSettingVisible } from "@/store/slice/chat";
import { Back, Dots, Rules } from "@/components/themeSvg";
import classnames from "classnames";
import { Image } from "antd-mobile";
import { replaceOssImage } from "@chat/shared";

function ChatHeader() {
    const { groupInfo } = useAppSelector(state => state.chat);
    const isSelect = useAppSelector(state => state.chat.isSelect);
    const navigate = useNavigate();
    const title = groupInfo?.groupName;
    const dispatch = useAppDispatch();
    const faceUrl = replaceOssImage(groupInfo?.groupLogo || "" ) + "?x-oss-process=image/resize,m_lfit,w_64";
    const renderTitle = () => {
        return <div className="title">
            <Image src={ faceUrl } className="title-image"></Image>
            <span className="title-text">{ title }</span>
        </div>;
    };
    const handleBack = () => {
        navigate("/home", { replace: true });
        sendTabBarVisible(true);
    };
    const renderHandler = () => {
        return <>
            <Rules className="handler-icon" onClick={ () => {
                dispatch(setRuleModalVisible(true));
            } }/>
            <Dots className="handler-icon" onClick={ () => {
                dispatch(setSettingVisible(true));
            } }/>
        </>;
    };
    return <div className="layout-header-chat">
        <div className={ classnames("layout-header-chat-content", { hideShadow: isSelect }) }>
            <div className="layout-header-chat-content__back">
                <Back className="navbar-backIcon" onClick={ handleBack }></Back>
            </div>
            <div className="layout-header-chat-content__title">
                {
                    renderTitle()
                }
            </div>
            <div className="layout-header-chat-content__handler">
                {
                    renderHandler()
                }
            </div>
        </div>
    </div>;
}

export default memo(ChatHeader);
;
