import { useAppSelector } from "@/store";
import { ChatMessageItem } from "@chat/zim-sdk";

function useSelfMessage(data: ChatMessageItem) {
    const { self } = useAppSelector(state => state.user);
    // 判断是否是自己的信息
    return self.userID === data.senderUserID;
}

export default useSelfMessage;
