import { MessageStatus, MessageType, ZIMMediaMessage } from "@chat/zim-sdk";
import { Image, ProgressCircle } from "antd-mobile";
import closePlay from "@/assets/svg/video-close.svg";
import videoPlay from "@/assets/svg/video-play.svg";

type LoadingBallProps = {
    style: any
    data: ZIMMediaMessage & { progress?: number }
}
const LoadingBall = (props: LoadingBallProps) => {
    const { style, data } = props;
    const { progress = 0 } = data;
    const isSending = data.sentStatus === MessageStatus.Sending;
    const renderBall = () => {
        if (isSending) {
            return <div className="message-loading-ball">
                <Image src={ closePlay } className="message-loading-ball__icon"/>
            </div>;
        }
        if (data.type === MessageType.Video) {
            return <div className="message-loading-ball">
                <Image src={ videoPlay } className="message-loading-ball__icon"/>
            </div>;
        }
        return null;
    };
    return <div style={ style } className="message-loading">
        {
            renderBall()
        }
        {
            isSending &&
            <ProgressCircle percent={ progress } style={ { "--track-color": "none", "--track-width": "4px" } }
                            className="message-loading-progress-bar"></ProgressCircle>
        }
    </div>;
};
export default LoadingBall;
