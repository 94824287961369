import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor("--whats-color-t3");

    return <span role="img" { ...props }>
<svg viewBox="0 0 24 25" fill="none">
<g clipPath="url(#clip0_8037_14270)">
<path
    d="M18.9943 10.171V8.19897H17.0223V10.171H18.9943ZM18.9943 13.1757V11.2036H17.0223V13.1757H18.9943ZM15.9897 10.171V8.19897H14.0176V10.171H15.9897ZM15.9897 13.1757V11.2036H14.0176V13.1757H15.9897ZM14.9801 17.214C15.5377 17.214 15.9897 16.762 15.9897 16.2044C15.9897 15.6468 15.5377 15.1948 14.9801 15.1948H9.01788C8.46032 15.1948 8.00832 15.6468 8.00832 16.2044C8.00832 16.7619 8.46031 17.2139 9.01788 17.2139L14.9801 17.214ZM6.97572 10.171V8.19897H5.00366V10.171H6.97572ZM6.97572 13.1757V11.2036H5.00366V13.1757H6.97572ZM8.00931 11.2046V13.1767H9.98137V11.2046H8.00931ZM8.00931 8.19997V10.172H9.98137V8.19997H8.00931ZM11.014 11.2046V13.1767H12.986V11.2046H11.014ZM11.014 8.19997V10.172H12.986V8.19997H11.014ZM20.0279 5.19531C20.5601 5.19531 21.0218 5.39096 21.4131 5.78223C21.8044 6.1735 22 6.63522 22 7.16737V17.2139C22 17.7461 21.8044 18.2078 21.4131 18.5991C21.0218 18.9904 20.5601 19.186 20.0279 19.186H3.97206C3.43991 19.186 2.97819 18.9904 2.58692 18.5991C2.19565 18.2078 2 17.7461 2 17.214V7.16737C2 6.63522 2.19565 6.1735 2.58692 5.78223C2.97819 5.39096 3.43991 5.19531 3.97206 5.19531H20.0279Z"
    fill={ color }/>
</g>
<defs>
<clipPath id="clip0_8037_14270">
<rect width="24" height="24" fill="white" transform="translate(0 0.191406)"/>
</clipPath>
</defs>
</svg>
    </span>;
}

export default memo(ThemeIcon);
