import { useAppSelector } from "@/store";
import { sendBackMessage } from "@/utils";
import React from "react";
import ThemeBtn from "@/layout/Header/ThemeBtn";
import { CloseNavbar } from "@/components/themeSvg";

function HomeHeader() {
    // const totalUnread = useAppSelector(state => state.home.totalUnread);
    const full = useAppSelector(state => state.common.full);

    return <div className="layout-header-home">
        <div className="layout-header-home-content">
            <div className="chat-logo">Chats</div>
            {/*{*/}
            {/*    totalUnread ? <div className="navbar-badge">{ totalUnread > 99 ? '99+' : totalUnread }</div> : null*/}
            {/*}*/}
            <div className="navbar-handler">
                <ThemeBtn></ThemeBtn>
                {
                    full && <CloseNavbar className="handler-icon" onClick={ sendBackMessage }/>
                }
            </div>
        </div>
        <div className="line"></div>
    </div>;
}

export default HomeHeader;
