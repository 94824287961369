import React, { memo } from "react";
import useBawThemeColor from "@/components/themeSvg/useBawThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useBawThemeColor();
    return <span role="img" { ...props }>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" color={color}>
    <path
        d="M5.13477 21.4512L7.00677 13.1172L17.3568 12.4692L7.00677 11.7672L5.13477 3.45117L23.1348 12.4512L5.13477 21.4512Z"
        fill="currentColor"/>
</svg>
    </span>;
}

export default memo(ThemeIcon);
