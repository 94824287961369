import React, { memo } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import { setRuleModalVisible } from "@/store/slice/chat";
import closeIcon from "@/assets/svg/close-center.svg";
import FloatModal from "@/components/FloatModal";
import { RuleRect } from "@/components/themeSvg";

function RulesModal() {
    const noticeBarModalVisible = useAppSelector(state => state.chat.ruleModalVisible);
    const dispatch = useAppDispatch();
    const closeModal = () => {
        dispatch(setRuleModalVisible(false));
    };
    return <FloatModal
        visible={ noticeBarModalVisible }
        onClose={ closeModal }
    >
        <div className="noticeModal">
            <div className="header">
                <div className="close-icon" onClick={ closeModal }>
                    <img src={ closeIcon } alt=""/>
                </div>
                <div className="header-title">Mirabel  💖 </div>

            </div>
            <div className="noticeModal-content">
                <div className="content-left">
                    <RuleRect className="content-left-icon"/>
                </div>
                {/*<Image className="content-left" src={ruleIcon}></Image>*/}
                <div className="content-right">
                    <div className="content-right-title"></div>
                    <div className="content-right-text">
                        <p>1. Do not spam, harass or offend other users. Also, avoid using capital letters!</p>
                        <p>2. Do not abuse others in the chat room, it will be banned.</p>
                        <p>3. No suspicious behavior that could be considered a potential scam.</p>
                        <p>4. Do not engage in any form of advertising/trading/selling/buying or providing services.</p>
                        <p>5. Don't use URL shorteners. Always submit the original link.</p>
                        <p>6. Use the chat rooms you need</p>
                    </div>
                </div>

            </div>
        </div>

    </FloatModal>;
}

export default memo(RulesModal);
