import React from "react";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}


function VoiceIcon(props: IconProps) {
    const { color } = props;
    return <span { ...props } role="img">
        <svg width="1em" height="1em" viewBox="0 0 10 14" color={ color }>
        <path
            d="M4.92105 9.07895C5.96947 9.07895 6.80947 8.23263 6.80947 7.18421L6.81579 3.39474C6.81579 2.34632 5.96947 1.5 4.92105 1.5C3.87263 1.5 3.02632 2.34632 3.02632 3.39474V7.18421C3.02632 8.23263 3.87263 9.07895 4.92105 9.07895ZM8.80526 7.18421C8.50877 7.18421 8.27341 7.42638 8.22448 7.7188C7.95592 9.32375 6.49514 10.4053 4.92105 10.4053C3.34697 10.4053 1.88618 9.32375 1.61762 7.7188C1.56869 7.42638 1.33333 7.18421 1.03684 7.18421C0.740352 7.18421 0.496281 7.42582 0.535727 7.71968C0.791816 9.6274 2.39233 11.145 4.28947 11.4284V12.8684C4.28947 13.2172 4.57224 13.5 4.92105 13.5C5.26986 13.5 5.55263 13.2172 5.55263 12.8684V11.4284C7.45025 11.1507 9.05109 9.63292 9.30657 7.7197C9.34581 7.42582 9.10175 7.18421 8.80526 7.18421Z"
            fill="currentColor"/>
    </svg>
    </span>;
}

export default VoiceIcon;
