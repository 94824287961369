import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable
}

function TopGroup(props: IconProps) {
    const { theme, ...attrs } = props;
    const [color] = useThemeColor(theme);

    return <span role="img" { ...attrs }>
     <svg viewBox="0 0 25 24" fill="none" color={ color }>
    <path
        d="M9.22142 3.98098V11.3153L7.40674 13.7349H12.1258M12.1258 13.7349L16.6639 13.7349L14.8492 11.3153V3.98096M12.1258 13.7349V20.6234M8.27344 3.37598H15.7969"
        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    </span>;
}

export default memo(TopGroup);
