import React, { memo } from "react";
import useBawThemeColor from "@/components/themeSvg/useBawThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useBawThemeColor();
    return <span role="img" { ...props }>
        <svg viewBox="0 0 16 16" fill="none">
    <path
        d="M7.21064 0.46511C7.611 -0.049648 8.389 -0.0496477 8.78936 0.465111L9.2392 1.04349C9.51409 1.39693 9.99042 1.52456 10.4052 1.35592L11.084 1.07995C11.6881 0.834332 12.3618 1.22333 12.4512 1.86931L12.5516 2.59512C12.6129 3.03865 12.9616 3.38735 13.4051 3.44869L14.1309 3.54907C14.7769 3.63841 15.1659 4.31218 14.9203 4.91628L14.6443 5.59505C14.4757 6.00983 14.6033 6.48615 14.9568 6.76104L15.5351 7.21089C16.0499 7.61124 16.0499 8.38925 15.5351 8.7896L14.9568 9.23944C14.6033 9.51433 14.4757 9.99066 14.6443 10.4054L14.9203 11.0842C15.1659 11.6883 14.7769 12.3621 14.1309 12.4514L13.4051 12.5518C12.9616 12.6131 12.6129 12.9618 12.5516 13.4054L12.4512 14.1312C12.3618 14.7772 11.6881 15.1662 11.084 14.9205L10.4052 14.6446C9.99042 14.4759 9.51409 14.6036 9.2392 14.957L8.78936 15.5354C8.389 16.0501 7.611 16.0501 7.21064 15.5354L6.7608 14.957C6.48591 14.6036 6.00958 14.4759 5.5948 14.6446L4.91604 14.9205C4.31194 15.1662 3.63817 14.7772 3.54883 14.1312L3.44845 13.4054C3.3871 12.9618 3.03841 12.6131 2.59487 12.5518L1.86906 12.4514C1.22309 12.3621 0.834088 11.6883 1.0797 11.0842L1.35568 10.4054C1.52432 9.99066 1.39669 9.51433 1.04325 9.23944L0.464866 8.7896C-0.0498922 8.38925 -0.0498918 7.61124 0.464866 7.21088L1.04325 6.76104C1.39669 6.48615 1.52432 6.00983 1.35567 5.59505L1.0797 4.91628C0.834088 4.31218 1.22309 3.63841 1.86906 3.54907L2.59487 3.44869C3.03841 3.38735 3.3871 3.03865 3.44845 2.59512L3.54883 1.86931C3.63817 1.22333 4.31194 0.834333 4.91604 1.07995L5.5948 1.35592C6.00958 1.52456 6.48591 1.39693 6.7608 1.04349L7.21064 0.46511Z"
        fill="#1DAB61"/>
    <path
        d="M7.34774 10.9849C7.01121 11.4038 6.38556 11.4377 6.00567 11.0578L4.17056 9.22243C3.95509 9.00693 3.95515 8.65754 4.17069 8.44211C4.38617 8.22675 4.73541 8.2268 4.95083 8.44222L6.12684 9.61823C6.3943 9.88569 6.83473 9.86177 7.07165 9.56692L11.0142 4.66042C11.205 4.42296 11.5522 4.38514 11.7896 4.57593C12.0271 4.76674 12.0649 5.11392 11.8741 5.3514L7.34774 10.9849Z"
        fill={color}/>
        </svg>
    </span>
}

export default memo(ThemeIcon);
