import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor("--whats-color-t3");

    return <span role="img" { ...props }>
<svg viewBox="0 0 24 24" fill="none" color={ color }>
  <path
      d="M13.9102 11.9414C13.9102 12.2066 14.0155 12.461 14.203 12.6485C14.3906 12.836 14.6449 12.9414 14.9102 12.9414C15.1754 12.9414 15.4297 12.836 15.6173 12.6485C15.8048 12.461 15.9102 12.2066 15.9102 11.9414C15.9102 11.6762 15.8048 11.4218 15.6173 11.2343C15.4297 11.0468 15.1754 10.9414 14.9102 10.9414C14.6449 10.9414 14.3906 11.0468 14.203 11.2343C14.0155 11.4218 13.9102 11.6762 13.9102 11.9414Z"
      fill="currentColor"/>
  <rect x="3.39941" y="4.19922" width="17.1992" height="15.6016" rx="2" stroke="currentColor" strokeWidth="2"/>
  <path
      d="M12.002 11.9849C12.002 10.4092 13.2793 9.13184 14.855 9.13184H20.5342V14.8379H14.855C13.2793 14.8379 12.002 13.5605 12.002 11.9849V11.9849Z"
      stroke="#566369" strokeWidth="2"/>
</svg>
    </span>;
}


export default memo(ThemeIcon);
