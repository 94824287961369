import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable;
}

function ThemeIcon(props: IconProps) {
    const { theme, ...attrs } = props;
    const [color] = useThemeColor(theme);

    return <span role="img" { ...attrs }>
            <svg viewBox="0 0 20 20" fill="none">
              <path
                  d="M5.94336 5.77004V4.0808C5.94336 3.51275 6.40778 3.05225 6.98067 3.05225H15.9707C16.5435 3.05225 17.008 3.51275 17.008 4.0808V12.9949C17.008 13.563 16.5435 14.0235 15.9707 14.0235H14.2478"
                  stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path
                  d="M13.0214 5.97754H4.03145C3.45856 5.97754 2.99414 6.43804 2.99414 7.00609V15.9202C2.99414 16.4883 3.45856 16.9488 4.03145 16.9488H13.0214C13.5943 16.9488 14.0587 16.4883 14.0587 15.9202V7.00609C14.0587 6.43804 13.5943 5.97754 13.0214 5.97754Z"
                  stroke={ color } strokeWidth="2" strokeLinejoin="round"/>
            </svg>
    </span>;
}

export default memo(ThemeIcon);
