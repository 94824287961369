import FloatModal from "@/components/FloatModal";
import { Image } from "antd-mobile";
import bellIcon from "@/assets/svg/notice-bell.svg";
import React, { memo } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import { setNoticeBarModalVisible } from "@/store/slice/chat";
import closeIcon from "@/assets/svg/close-center.svg";

function NoticeDetailModal() {
    const groupInfo = useAppSelector(state => state.chat.groupInfo);
    const noticeBarModalVisible = useAppSelector(state => state.chat.noticeBarModalVisible);
    const dispatch = useAppDispatch();
    const closeModal = () => {
        dispatch(setNoticeBarModalVisible(false));
    };
    return <FloatModal
        visible={ noticeBarModalVisible }
        onClose={ closeModal }
    >
        <div className="noticeModal">
            <div className="header">
                <div className="close-icon" onClick={ closeModal }>
                    <img src={ closeIcon } alt=""/>
                </div>
                <div className="header-title">Mirabel 💖</div>
            </div>
            <div className="noticeModal-content">
                <Image src={ bellIcon } className="content-left"></Image>
                <div className="content-right">
                    <div className="content-right-title">Announcements</div>
                    <div className="content-right-text">
                        { groupInfo?.groupNotice }
                    </div>
                </div>
            </div>
        </div>

    </FloatModal>;
}

export default memo(NoticeDetailModal);
