import store from "@/store";
import type { ThemeVariable } from "@/theme";

function useThemeColor(type: ThemeVariable = "--whats-color-t2") {
    // 因为需要用到静态方法，所以不使用hooks
    const { value, name } = store.getState().common.theme;
    switch (name) {
        case "dark":
            const darkColor = value[type];
            return [darkColor];
        case "light":
        default:
            const lightColor = value[type];
            return [lightColor];
    }
}

export default useThemeColor;
