import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable;
}

function ThemeIcon(props: IconProps) {
    const { theme, ...attrs } = props;
    const [color] = useThemeColor(theme);

    return <span role="img" { ...attrs }>
            <svg viewBox="0 0 25 25" fill="none">
              <path d="M18.6133 21.0928H6.61328" stroke={ color } strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round"/>
              <path
                  d="M5.76953 10.1011C5.40709 10.4635 5.40709 11.0511 5.76953 11.4136L11.8268 17.4708C12.2611 17.9052 12.9654 17.9052 13.3998 17.4708L19.457 11.4136C19.8195 11.0511 19.8195 10.4635 19.457 10.1011C19.0946 9.73864 18.507 9.73864 18.1445 10.1011L13.5508 14.6948L13.5508 3.83702C13.5508 3.31926 13.131 2.89952 12.6133 2.89952C12.0955 2.89952 11.6758 3.31925 11.6758 3.83702L11.6758 14.6948L7.08203 10.1011C6.71959 9.73864 6.13197 9.73864 5.76953 10.1011Z"
                  fill={ color }/>
            </svg>
    </span>;
}

export default memo(ThemeIcon);
