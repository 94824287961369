import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable;
}

function ThemeIcon(props: IconProps) {
    const {theme, ...attrs} = props;
    const [color] = useThemeColor(theme);

    return <span role="img" { ...attrs }>
        <svg viewBox="0 0 25 24" color={ color }>
            <path
                d="M13.2274 5.15723C12.8649 4.79479 12.2773 4.79479 11.9149 5.15723L5.85762 11.2145C5.42324 11.6488 5.42324 12.3531 5.85762 12.7875L11.9149 18.8447C12.2773 19.2072 12.8649 19.2072 13.2274 18.8447C13.5898 18.4823 13.5898 17.8947 13.2274 17.5322L8.63361 12.9385H19.4914C20.0092 12.9385 20.4289 12.5187 20.4289 12.001C20.4289 11.4832 20.0092 11.0635 19.4914 11.0635H8.63361L13.2274 6.46973C13.5898 6.10729 13.5898 5.51966 13.2274 5.15723Z"
                fill="currentColor"/>
        </svg>
    </span>;
}

export default memo(ThemeIcon);
