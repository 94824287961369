import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { useAppSelector } from "@/store";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor("--whats-button-icon");
    const {name, value} = useAppSelector(state => state.common.theme)

    return <span role="img" { ...props }>
<svg viewBox="0 0 25 24" fill="none">
    <rect x="0.5" width="24" height="24" rx="9" fill={name === "dark" ? "#D8FDD2" : value["--whats-base-color"]}/>
    <circle cx="12.5" cy="7.5" r="0.75" fill={value["--whats-button-icon"]} stroke={color} strokeWidth="1.5"/>
    <path d="M12.5 12L12.5 18" stroke={value["--whats-button-icon"]} strokeWidth="3" strokeLinecap="round"/>
</svg>

    </span>;
}

export default memo(ThemeIcon);
