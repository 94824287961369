import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable;
}

function ThemeIcon(props: IconProps) {
    const { theme, ...attrs } = props;
    const [color] = useThemeColor(theme);

    return <span role="img" { ...attrs }>
<svg viewBox="0 0 25 24" fill="none" color={ color }>
  <path
      d="M10.3887 4.01123C10.3887 3.12132 11.1101 2.3999 12 2.3999C12.8899 2.3999 13.6113 3.12132 13.6113 4.01123V5.2877H10.3887V4.01123Z"
      fill="currentColor"/>
  <path
      d="M12 21.6265C13.0393 21.6265 13.8818 20.7839 13.8818 19.7446H10.1182C10.1182 20.7839 10.9607 21.6265 12 21.6265Z"
      fill="currentColor"/>
  <path d="M5.5 17.6504H18.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path
      d="M7.16608 9.99414C7.16608 7.785 8.95694 5.99414 11.1661 5.99414H12.6933C14.9024 5.99414 16.6933 7.785 16.6933 9.99414V17.6416H7.16608V9.99414Z"
      stroke="currentColor" strokeWidth="2"/>
</svg>



    </span>;
}

export default memo(ThemeIcon);
