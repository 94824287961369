import { useUserInfo } from "@/hooks/useUserInfo";
import { ZIMAudioMessage, MessageReceiptStatus } from "@chat/zim-sdk";
import VoiceIcon from "@/components/normalSvg/Voice";
import { formattedTime } from "@chat/shared";
import cls from "./lasetMessageStyle.module.scss";

type AudioProps = {
    message: ZIMAudioMessage
};

export default function Audio(props: AudioProps) {
    const { senderUserID, receiptStatus, audioDuration } = props.message;
    const { userInfo } = useUserInfo(senderUserID);
    return <div className={ cls.container }>
        <span>{ userInfo?.userName }:</span>
        <VoiceIcon color={ receiptStatus === MessageReceiptStatus.Done ? "#20C164" : "#84969A" }
                   className="text-inner-image"></VoiceIcon>
        <span>{ formattedTime(audioDuration * 1000).join(":") }</span>
    </div>;
}
