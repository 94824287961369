import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function CloseNavbar(props: IconProps) {
    const [color] = useThemeColor();

    return <span role="img" { ...props }>
        <svg color={ color } viewBox="0 0 25 25" fill="none">
    <path
        d="M16.7503 5.05572C15.4195 4.16262 13.818 3.6416 12.0948 3.6416C7.47476 3.6416 3.72949 7.38687 3.72949 12.0069C3.72949 16.6269 7.47476 20.3722 12.0948 20.3722C13.818 20.3722 15.4195 19.8512 16.7503 18.9581"
        stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M11.1221 11.9988H20.557M20.557 11.9988L18.4833 9.2041M20.557 11.9988L18.4833 14.837" stroke="currentColor"
          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    </span>;
}

export default memo(CloseNavbar);
