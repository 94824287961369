import React from "react";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}


function ErrorIcon(props: IconProps) {
    const { color, ...attrs } = props;
    return <span { ...attrs } role="img">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" fill="none">
    <rect x="0.45752" y="0.5" width="28" height="28" rx="14" fill="#C90000"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M14.4572 5.16602C13.1685 5.16602 12.1238 6.21068 12.1238 7.49935V13.3327C12.1238 14.6213 13.1685 15.666 14.4572 15.666C15.7458 15.666 16.7905 14.6213 16.7905 13.3327V7.49935C16.7905 6.21068 15.7458 5.16602 14.4572 5.16602ZM14.4572 17.9993C13.1685 17.9993 12.1238 19.044 12.1238 20.3327C12.1238 21.6213 13.1685 22.666 14.4572 22.666C15.7458 22.666 16.7905 21.6213 16.7905 20.3327C16.7905 19.044 15.7458 17.9993 14.4572 17.9993Z"
          fill="white"/>
</svg>
    </span>;
}

export default ErrorIcon;
