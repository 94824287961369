import Header from "./Header";
import Content from "./content";
import './layout.scss';
import CircleLoading from "@/components/Loading/Circle";
import React, { useEffect } from "react";
import { useAppSelector } from "@/store";
import SettingModal from "@/components/SettingModal";
import { sendImRouteChange, sendLoginMessage } from "@/utils";
import LoginModal from "@/components/LoginModal";
import EditorModal from "@/components/EditorModal";
import { useLocation } from "react-router-dom";
import { logout } from "@chat/zim-sdk";
import DismissModal from "./DismissModal";

export default function Layout() {
    const loading = useAppSelector(state => state.common.loading);
    const { kickVisible, expiredVisible } = useAppSelector(state => state.common);
    const location = useLocation();
    useEffect(() => {
        sendImRouteChange(location);
    }, [location]);
    useEffect(() => {
        window.addEventListener("beforeunload", logout);
        return () => {
            window.removeEventListener("beforeunload", logout);
        };
    }, []);
    return <div className="layout">
        <Header></Header>
        <Content></Content>
        {
            loading ? <CircleLoading/> : null
        }
        <SettingModal title="Friendly reminder" visible={ kickVisible } confirm={ sendLoginMessage }>
            Your account has logged in on another device!
        </SettingModal>
        <SettingModal title="Friendly reminder" visible={ expiredVisible } confirm={ sendLoginMessage }>
            Current login has expired, please log in again!
        </SettingModal>
        <LoginModal/>
        <EditorModal/>
        <DismissModal/>
    </div>;
}
