import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor();

    return <span role="img" { ...props }>
        <svg viewBox="0 0 21 21" color={ color } fill="none">
      <path d="M4.72961 5.10718V17.0519H15.2691V5.10718H4.72961Z" stroke="currentColor" strokeWidth="2"
            strokeLinejoin="round"/>
  <path d="M8.59406 8.62085V13.1879" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
        strokeLinejoin="round"/>
  <path d="M11.4046 8.62085V13.1879" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
        strokeLinejoin="round"/>
  <path d="M2.97302 5.10718H17.0256" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
        strokeLinejoin="round"/>
  <path d="M7.18884 5.10764L8.34432 2.99976H11.6776L12.8099 5.10764H7.18884Z" stroke="currentColor" strokeWidth="2"
        strokeLinejoin="round"/>
        </svg>
    </span>;
}

export default memo(ThemeIcon);
