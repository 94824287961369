import cls from "./foot.module.scss";
import cameraIcon from "@/assets/svg/camera.svg";
import galleryIcon from "@/assets/svg/gallery.svg";
import { Image } from "antd-mobile";
import { useAppSelector } from "@/store";
import { AnimatePresence, motion } from "framer-motion";
import { ChangeEvent, useRef, useState } from "react";
import useFileMessage from "@/pages/Chat/feature/hooks/useFileMessage";
import useSendMessage from "@/pages/Chat/feature/hooks/useSendMessage";

const list = [
    {
        icon: cameraIcon,
        text: "Video",
        accept: ".mp4",
    },
    {
        icon: galleryIcon,
        text: "Gallery",
        accept: "image/*",
    }
];

function MenusCard() {
    const { inputMenusVisible } = useAppSelector(state => state.chat);
    const {conversationDetail} = useAppSelector(state => state.chat)
    const [accept, setAccept] = useState("image/*");
    const { createImageOrVideoMessage } = useFileMessage();
    const { sendMessage } = useSendMessage();
    const inputRef = useRef<HTMLInputElement>(null);
    const handleClick = (data: any) => {
        setAccept(data.accept);
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.click();
            }
        }, 0);
    };
    const listenFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![0];
        createImageOrVideoMessage(file).then(message => {
            // 发送消息
            sendMessage({
                message,
                conversationID: conversationDetail.conversationID
            });
        });
    };
    return <AnimatePresence>
        {
            inputMenusVisible && <motion.div
                initial={ { opacity: 0, y: -10 } }
                animate={ { opacity: 1, y: 0 } }
                exit={ { opacity: 0, y: -10 } }
                className={ cls.menus }>
                <input type="file" ref={ inputRef } accept={ accept } style={ { display: "none" } }
                       onChange={ listenFileChange }/>
                {
                    list.map(item => {
                        return <div className={ cls.menus__item } key={ item.text } onClick={ () => handleClick(item) }>
                            <Image src={ item.icon } className={ cls.menus__item__icon }/>
                            <div className={ cls.menus__item__text }>{ item.text }</div>
                        </div>;
                    })
                }
            </motion.div>
        }

    </AnimatePresence>;
}


export default MenusCard;
