import "./style.scss";
import { CenterPopup } from "antd-mobile";
import React, { ReactNode } from "react";

type SettingModalProps = {
    title: string | ReactNode;
    visible: boolean;
    cancel?: () => void;
    cancelText?: string | ReactNode;
    confirm: () => void;
    confirmText?: string | ReactNode;
    children?: ReactNode;
    showCancel?: boolean;
    onMaskClick?: () => void
}

function SettingModal(props: SettingModalProps) {
    const { visible, confirm, cancel, title, confirmText, cancelText, children, onMaskClick } = props;
    const handleMaskClick = () => {
        if (onMaskClick) {
            onMaskClick();
            return;
        }
        cancel?.();
    };
    return <CenterPopup
        visible={ visible }
        // @ts-ignore
        bodyStyle={ { '--border-radius': '20px' } }
        onMaskClick={ handleMaskClick }
        getContainer={ () => document.body }
    >
        <div className="common-setting-modal">
            <div className="title">{ title }</div>
            <div className="content">
                {
                    children
                }
            </div>
            <div className="handler">
                {
                    cancel && <div className="btn" onClick={ cancel }>{ cancelText || "Cancel" }</div>
                }
                <div className="btn" onClick={ confirm }>{ confirmText || "Confirm" }</div>
            </div>
        </div>

    </CenterPopup>;
}

export default SettingModal;
