import React, { memo } from "react";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    self: boolean;
}


function ThemeIcon(props: IconProps) {
    const { color = '#1F2C34', self, ...attrs } = props;

    return <span role="img" { ...attrs }>
            <svg viewBox="0 0 8 12" fill={ color }>
                {
                    self ? <path d="M7.26636 3.28037L0 12V0H5.72992C7.42559 0 8.35191 1.97771 7.26636 3.28037Z"/> :
                    <path d="M0.733638 3.28037L8 12V0H2.27008C0.574408 0 -0.351907 1.97771 0.733638 3.28037Z"/>

                }
            </svg>

    </span>;
}

export default memo(ThemeIcon);
