import { Skeleton } from "antd-mobile";
const skeletonArr = Array(8).fill(1);

export default function Loading() {
    return <div className="skeleton-message">
        {
            skeletonArr.map((_, i) => {
                return <div className="skeleton-message-item" key={ i }>
                    <Skeleton className="skeleton-message-item-icon" animated></Skeleton>
                    <Skeleton.Paragraph lineCount={ 2 } animated
                                        className="skeleton-message-item-content"></Skeleton.Paragraph>
                </div>;
            })
        }
    </div>
}
