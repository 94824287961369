import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";
import { ThemeVariable } from "@/theme";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    theme?: ThemeVariable;
}

function ThemeIcon(props: IconProps) {
    const { theme, className } = props;
    const [color] = useThemeColor(theme);
    return <svg viewBox="0 0 15 15" fill={ color } className={ className }>
        <g clipPath="url(#clip0_8863_46851)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.5 4.02627C14.5 3.6088 14.0186 3.37517 13.6907 3.63345L11.5 5.35859V7.63359L13.6907 9.35873C14.0186 9.61702 14.5 9.38339 14.5 8.96592V4.02627Z"
                  fill="currentColor"/>
            <path
                d="M11.5 10.9961V2.99609C11.5 2.44381 11.0523 1.99609 10.5 1.99609H1.50001C0.947722 1.99609 0.500006 2.44382 0.500013 2.99611L0.500115 10.9961C0.500122 11.5484 0.947834 11.9961 1.50011 11.9961H10.5C11.0523 11.9961 11.5 11.5484 11.5 10.9961Z"
                fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_8863_46851">
                <rect width="14" height="14" fill="white" transform="translate(0.5 0.191406)"/>
            </clipPath>
        </defs>
    </svg>;
}

export default memo(ThemeIcon);

