import { useMemo } from "react";
import SettingModal from "@/components/SettingModal";
import { useAppDispatch, useAppSelector } from "@/store";
import { getUserByGroupId, setBlockVisible } from "@/store/slice/personal";
import { toast } from "@/utils";
import { useCustomMessage } from "@/hooks/useCustomMessage";
import { useParams } from "react-router-dom";

function BlockModal() {
    const { blockVisible, user, userId } = useAppSelector(state => state.personal);
    const { sendBlockFlag } = useCustomMessage();
    const dispatch = useAppDispatch();
    const { conversationID } = useParams();
    const cancel = () => {
        reset();
    };
    const onConfirm = () => {
        sendBlockFlag(conversationID!, userId).then(() => {
            dispatch(getUserByGroupId());
            toast("You have blocked this user");
            reset();
        });
    };
    const reset = () => {
        dispatch(setBlockVisible(false));
    };
    const title = useMemo(() => {
        return `Block ${ user.userName }?`;
    }, [user]);
    return <SettingModal title={ title }
                         confirmText="Block"
                         cancel={ cancel }
                         visible={ blockVisible }
                         confirm={ onConfirm }>
        Users who have been blocked cannot enter group chats or send messages
    </SettingModal>;
}

export default BlockModal;
