import React, { memo } from "react";
import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    const [color] = useThemeColor();

    return <span role="img" { ...props }>
<svg viewBox="0 0 21 20" fill="none" color={ color }>

  <path
      d="M12.25 16.0005C12.25 17.1005 11.35 18.0005 10.25 18.0005C9.15 18.0005 8.25 17.1005 8.25 16.0005C8.25 14.9005 9.15 14.0005 10.25 14.0005C11.35 14.0005 12.25 14.9005 12.25 16.0005ZM10.25 8.00049C9.15 8.00049 8.25 8.90049 8.25 10.0005C8.25 11.1005 9.15 12.0005 10.25 12.0005C11.35 12.0005 12.25 11.1005 12.25 10.0005C12.25 8.90049 11.35 8.00049 10.25 8.00049ZM10.25 2.00049C9.15 2.00049 8.25 2.90049 8.25 4.00049C8.25 5.10049 9.15 6.00049 10.25 6.00049C11.35 6.00049 12.25 5.10049 12.25 4.00049C12.25 2.90049 11.35 2.00049 10.25 2.00049Z"
      fill="currentColor"/>
</svg>
    </span>;
}

export default memo(ThemeIcon);
