import React, { useLayoutEffect, useState, memo } from "react";
import { Image } from "antd-mobile";
import MessageState from "@/pages/Chat/feature/MessageItem/MessageState";
import { getImageInfo, getVmSize } from "@chat/shared";
import ImageViewer from "@/components/ImageViewer";
import { ZIMImageMessage } from "@chat/zim-sdk";
import LoadingBall from "@/pages/Chat/feature/MessageItem/LoadingMedia";

type ImageMessageProps = {
    data: ZIMImageMessage
}
const getStyle = (width: number, height: number) => {
    let localWidth = width > 200 ? 200 : width;
    const scale = localWidth / width;
    const localHeight = height * scale;
    return {
        width: getVmSize(localWidth),
        height: getVmSize(localHeight),
        borderRadius: getVmSize(8),
        maxHeight: getVmSize(300),
        minWidth: getVmSize(80),
    };
};

function PictureMessage(props: ImageMessageProps) {
    const { data } = props;
    const { thumbnailWidth, thumbnailHeight, thumbnailDownloadUrl, largeImageDownloadUrl } = data;
    const [style, setStyle] = useState(getStyle(thumbnailWidth, thumbnailHeight));
    useLayoutEffect(() => {
        if (!thumbnailWidth && !thumbnailHeight) {
            getImageInfo(thumbnailDownloadUrl).then(res => {
                setStyle(getStyle(res.width, res.height));
            });
        }

    }, [thumbnailWidth, thumbnailHeight]);
    return <>
        <div className="image-message">
            <div style={ { position: "relative", overflow: "hidden", ...style } }>
                <Image
                    onClick={ () => {
                        ImageViewer.show({ image: largeImageDownloadUrl || thumbnailDownloadUrl });
                    } } style={ style }
                    fit="cover"
                    className="image-message-img" src={ thumbnailDownloadUrl }
                    fallback={ <div style={ style } className="common-placeholder"/> }
                    placeholder={ <div style={ style } className="common-placeholder"></div> }>
                </Image>
                <LoadingBall style={ style } data={ data }/>
                <MessageState data={ data } className="message-state_float"/>
            </div>

        </div>
    </>;
}

export default memo(PictureMessage);
